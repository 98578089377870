import React, { useCallback, useRef } from "react";
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonItem, IonList, IonModal, IonRow, IonText, useIonRouter } from "@ionic/react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { setFocusMapEvent } from "app/slices/eventSlices";
import { RootState } from "app/store";
import { IEventDetails } from "app/variables";
import dayjs from "dayjs";
import i18n from "i18n";
import greenDot from "media/greenDot.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import gMap from "../media/gMapLogo.png";
import { OrgFieldDisplay } from "./EventCard";
import { ModalHeader } from "./ModalHeader";
import { adrObjToStr } from "helpers/address";
import EvtPosterDisplay from "./EvtPosterDisplay";
import EvtDateDisplay from "./EvtDateDisplay";
import CopyButton from "./CopyButton";

const MAP_HEIGHT = window.innerHeight - 130;
const DEFAULT_ZOOM = 5.5;
const DEFAULT_CENTER = {
  lat: 54.4316679,
  lng: -4.3016133,
};

interface EventPageProps {
  angle: number;
  tilt: number;
  setIsVector: (args0: boolean) => void;
  isBlur: boolean;
  onReset: VoidFunction;
}

function MapEvents(props: EventPageProps) {
  const { t } = useTranslation();
  const rdxEvt = useSelector((state: RootState) => state.events);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    mapIds: [process.env.REACT_APP_MAP_ID!],
    googleMapsApiKey: String(process.env.REACT_APP_GEO_KEY),
  });
  const [blink, setBlink] = useState("");
  const [zoomValue, setZoomValue] = useState(DEFAULT_ZOOM);
  const [viewCenter, setViewCenter] = useState(DEFAULT_CENTER);
  const [viewEvtDetails, setViewEvtDetails] = useState<IEventDetails | null>(null);
  const [copied, setCopied] = useState(false);
  const dispatch = useDispatch();
  const mapRef = useRef<GoogleMap>(null);
  const router = useIonRouter();

  // useEffect(() => {
  //   window.navigator.geolocation.getCurrentPosition((e) => {
  //     const coords = e.coords;
  //     dispatch(setUserLocation({ lat: coords.latitude, lng: coords.longitude }));
  //     setViewCenter({
  //       lat: coords.latitude,
  //       lng: coords.longitude,
  //     });
  //   });
  // }, []);

  useEffect(() => {
    if (blink) {
      setTimeout(() => setBlink(""), 1000);
    }
  }, [blink]);

  useEffect(() => {
    if (rdxEvt.focusMapEvent) {
      setZoomValue(17);
      setViewCenter({ lat: rdxEvt.focusMapEvent.geolocation.lat ?? 0, lng: rdxEvt.focusMapEvent.geolocation.lng ?? 0 });
    }
  }, [rdxEvt.focusMapEvent]);

  const copyToClipboard = useCallback(() => {
    if (!viewEvtDetails) return;
    navigator.clipboard.writeText(`${window.location.href}/${viewEvtDetails.eventId}\n${viewEvtDetails.eventName} - ${dayjs(viewEvtDetails.eventDates[0].start).format("YYYY-MM-DD")}`);
    setCopied(true);
  }, [viewEvtDetails]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  useEffect(() => {
    if (props.isBlur) {
      setViewCenter(DEFAULT_CENTER);
      setZoomValue(DEFAULT_ZOOM);
      dispatch(setFocusMapEvent(null));
      props.onReset();
    }
  }, [props.isBlur]);

  return (
    <IonContent>
      {isLoaded && (
        <GoogleMap
          ref={mapRef}
          onTilesLoaded={() => {
            const mapType = mapRef.current?.state.map?.getRenderingType();
            if (mapType !== "VECTOR") {
              props.setIsVector(false);
            } else {
              props.setIsVector(true);
            }
          }}
          mapContainerStyle={{ height: MAP_HEIGHT, width: "100%" }}
          center={viewCenter}
          zoom={zoomValue}
          heading={props.angle}
          tilt={props.tilt}
          options={{
            streetViewControl: false,
            mapId: process.env.REACT_APP_MAP_ID,
          }}
        >
          {rdxEvt.events.map((point, idx) => (
            <Marker
              key={point.eventId + "marker" + idx}
              options={{ icon: greenDot, opacity: 0.8 }}
              animation={rdxEvt.focusMapEvent && rdxEvt.focusMapEvent.eventId === point.eventId ? 1 : 0}
              position={{ lat: point.geolocation.lat ?? 0, lng: point.geolocation.lng ?? 0 }}
              onClick={() => {
                if (rdxEvt.focusMapEvent?.eventId === point.eventId) {
                  dispatch(setFocusMapEvent(null));
                } else {
                  setViewEvtDetails(point);
                  dispatch(setFocusMapEvent(point));
                }
              }}
            ></Marker>
          ))}
        </GoogleMap>
      )}

      <IonModal isOpen={!!viewEvtDetails} onDidDismiss={() => setViewEvtDetails(null)}>
        <ModalHeader
          title={(i18n.language === "en" ? viewEvtDetails?.eventName : viewEvtDetails?.eventNameChi) || viewEvtDetails?.eventName || ""}
          closeButton
          closeFunction={() => setViewEvtDetails(null)}
        />
        {viewEvtDetails ? (
          <IonContent>
            <IonGrid>
              <IonList>
                <IonRow>
                  <IonCol className="flex-center">
                    <IonButtons>
                      <IonButton onClick={() => window.open(`https://www.google.com/maps?saddr=My+Location&daddr=${viewEvtDetails.geolocation.lat},${viewEvtDetails.geolocation.lng}`)} size="small">
                        <IonText className="ion-margin">{t("direction")}</IonText>
                        <img src={gMap} alt={gMap} style={{ maxWidth: "20px" }} />
                      </IonButton>
                    </IonButtons>
                  </IonCol>
                </IonRow>
                {/* {viewEvtDetails.eventPoster && (
                  <IonItem lines="none">
                    <IonCol className="flex-center">
                      <img src={viewEvtDetails.eventPoster} alt={viewEvtDetails.eventPoster} />
                    </IonCol>
                  </IonItem>
                )} */}

                {viewEvtDetails.eventPoster && !!viewEvtDetails.eventPoster.length && <EvtPosterDisplay eventPoster={viewEvtDetails.eventPoster} />}
                {viewEvtDetails.organisation && <OrgFieldDisplay name={t("host") + " :"} org={viewEvtDetails.organisation} />}
                <EvtDateDisplay eventDateType={viewEvtDetails.eventDateType} eventDates={viewEvtDetails.eventDates} />
                <IonItem lines="none">
                  <IonText>
                    <b>{`${t("address")} :`}</b>
                    {adrObjToStr(viewEvtDetails.eventAddress)}
                  </IonText>
                </IonItem>
                {!!viewEvtDetails.website && (
                  <IonItem lines="none">
                    <IonText>
                      <b>{t("website")}:</b>
                    </IonText>
                    <IonText
                      className="ion-margin-horizontal"
                      style={{
                        cursor: "pointer",
                        textTransform: "lowercase",
                        textDecoration: "underline",
                      }}
                      color="primary"
                      onClick={() => {
                        if (viewEvtDetails.website?.startsWith("http")) {
                          window.open(viewEvtDetails.website);
                        } else {
                          window.open(`http://${viewEvtDetails.website}`);
                        }
                      }}
                    >
                      {viewEvtDetails.website}
                    </IonText>
                  </IonItem>
                )}
                {!!viewEvtDetails.description && (
                  <IonItem lines="none">
                    <IonText>
                      <b>{t("eventDes") + " :"}</b>
                      {i18n.language === "en" ? (
                        <p style={{ whiteSpace: "pre-wrap" }}>{`${viewEvtDetails.description}`}</p>
                      ) : (
                        <p style={{ whiteSpace: "pre-wrap" }}>{`${viewEvtDetails.descriptionChi ? viewEvtDetails.descriptionChi : viewEvtDetails.description}`}</p>
                      )}
                    </IonText>
                  </IonItem>
                )}
                {!!viewEvtDetails.coOrg?.organizationName && <OrgFieldDisplay name={"Co-organiser:"} org={viewEvtDetails.coOrg} />}
                {!!viewEvtDetails.supportOrg?.organizationName && <OrgFieldDisplay name={"Support organisation:"} org={viewEvtDetails.supportOrg} />}
              </IonList>
              <br />
              <IonRow>
                <IonCol className="ion-text-end">
                  <IonButton
                    expand="block"
                    size="default"
                    onClick={() => {
                      router.push(`/events/${viewEvtDetails.eventId}`);
                      setViewEvtDetails(null);
                    }}
                  >
                    {t("visitEventPage")}
                  </IonButton>
                </IonCol>
                <CopyButton expand color={copied ? "success" : "primary"} copyFunction={() => (!copied ? copyToClipboard() : {})} text={copied ? t("copiedToClipboard") : t("copyEventDetails")} />
              </IonRow>
            </IonGrid>
          </IonContent>
        ) : null}
      </IonModal>
    </IonContent>
  );
}

export default MapEvents;
