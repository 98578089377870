import React from "react";
import { IonButton, IonCard, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonIcon, IonLoading, IonRow, useIonRouter } from "@ionic/react";
import { chevronUpCircle } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAnnouncement, baseUrl } from "../app/variables";
import { Layout } from "../pages/Page";
import { AnnouncementContent } from "./AnnouncementPage";
import CopyButton from "components/CopyButton";
import { useSelector } from "react-redux";
import { selectAllAnnouncements } from "app/slices/announcementSlices";

export default function AnnouncementListPage() {
  const { t } = useTranslation();
  const rdxAnn = useSelector(selectAllAnnouncements);
  const [isLoading, setIsLoading] = useState(false);
  const [dataState, setDataState] = useState<IAnnouncement[]>([]);
  const [copied, setCopied] = useState("");
  const contentRef = useRef<HTMLIonContentElement>(null);

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    setDataState(rdxAnn.announcements);
    // try {
    //   const res = await getAllAnnouncements();
    //   if (res && res.msg === "success" && !!res.data.length) {
    //     setDataState(res.data);
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    setIsLoading(false);
    // }
  }, [rdxAnn.announcements]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(""), 3000);
    }
  }, [copied]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return (
    <Layout name={t("m-announcements")}>
      <IonContent ref={contentRef}>
        <IonGrid>
          {!dataState.length ? (
            <IonRow>
              <IonCol>{t("noAnnouncementYet")}</IonCol>
            </IonRow>
          ) : (
            dataState.map((i, idx) => <AnnouncementCard ann={i} key={i.id + "announcement" + idx} copied={copied} setCopied={setCopied} />)
          )}
        </IonGrid>
        <IonFab slot="fixed" vertical="bottom" horizontal="end" className="ion-margin ion-padding">
          <IonFabButton onClick={() => contentRef.current?.scrollToTop(600)}>
            <IonIcon icon={chevronUpCircle} />
          </IonFabButton>
        </IonFab>
        <br />
        <br />
        <br />

        {/* <OrgModal viewOrg={viewOrg} setViewOrg={setViewOrg} /> */}
      </IonContent>
      <IonLoading isOpen={isLoading} message={"Loading"} />
    </Layout>
  );
}

interface AnnouncementCardProps {
  ann: IAnnouncement;
  copied: string;
  setCopied: (args0: string) => void;
}

export const AnnouncementCard = (props: AnnouncementCardProps) => {
  const { ann, copied, setCopied } = props;
  const { t } = useTranslation();
  const router = useIonRouter();

  const copyToClipboard = (id: string) => {
    if (!ann) return;
    if (id) {
      navigator.clipboard.writeText(`${ann.title}\n${baseUrl}/announcement/${id}`);
    } else {
      navigator.clipboard.writeText(`${ann.title}\n${window.location.href}`);
    }
    setCopied(ann.id);
  };

  return (
    <>
      <IonCard id={ann.id} className="ion-padding-horizontal" style={{ transition: "all 0.8s" }}>
        <IonGrid>
          <AnnouncementContent announcement={ann} color={"primary"} copied={copied} setCopied={setCopied} />
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                color="primary"
                onClick={() => {
                  router.push(`/announcement/${ann.id}`);
                }}
              >
                {t("visitAnnouncementPage")}
              </IonButton>
            </IonCol>
            <CopyButton expand color={copied ? "success" : "primary"} copyFunction={() => (!copied ? copyToClipboard(ann.id) : {})} text={copied ? t("copiedToClipboard") : t("copyAnnouncement")} />
          </IonRow>
        </IonGrid>
      </IonCard>
    </>
  );
};
