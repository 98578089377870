import React from "react";
import { IonCol, IonGrid, IonItem, IonRow, IonSearchbar, IonText } from "@ionic/react";
import { setFilteredEvents } from "app/slices/eventSlices";
import { RootState } from "app/store";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EventCard } from "./EventCard";

function ListEvents() {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [copied, setCopied] = useState("");
  const allEvts = useSelector((state: RootState) => state.events);
  const dispatch = useDispatch();
  const timeoutRef = useRef<any>(null);

  useEffect(() => {
    if (!searchText) {
      dispatch(setFilteredEvents([]));
    } else {
      const filtered = allEvts.events.filter((evt) => {
        const street = `${evt.eventAddress.streetNo.toLowerCase()} ${evt.eventAddress.route.toLowerCase()}`;
        return (
          evt.eventName.toLowerCase().includes(searchText.toLowerCase()) ||
          evt.eventNameChi?.toLowerCase().includes(searchText.toLowerCase()) ||
          evt.eventAddress.postcode.toLowerCase().includes(searchText.toLowerCase()) ||
          evt.eventAddress.adminArea2.toLowerCase().includes(searchText.toLowerCase()) ||
          evt.eventAddress.adminArea1.toLowerCase().includes(searchText.toLowerCase()) ||
          evt.eventAddress.country.toLowerCase().includes(searchText.toLowerCase()) ||
          evt.eventAddress.postalTown.toLowerCase().includes(searchText.toLowerCase()) ||
          street.includes(searchText.toLowerCase())
        );
      });
      dispatch(setFilteredEvents(filtered));
    }
  }, [searchText]);

  useEffect(() => {
    if (copied) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => setCopied(""), 3000);
    }
  }, [copied]);

  return (
    <IonGrid>
      <IonItem lines="none">
        <IonSearchbar
          showClearButton="always"
          onIonClear={() => {
            dispatch(setFilteredEvents([]));
            setSearchText("");
          }}
          placeholder={`${t("listEvtSearch")}`}
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value!)}
        />
      </IonItem>
      {!allEvts.events.length ? (
        <IonRow>
          <IonCol className="ion-text-center">
            <IonText>{t("noEvt")}</IonText>
          </IonCol>
        </IonRow>
      ) : allEvts.filteredEvents.length ? (
        allEvts.filteredEvents.map((i, idx) => <EventCard copied={copied} setCopied={setCopied} event={i} idx={idx} key={i.eventId + "eventPoint" + idx} />)
      ) : (
        allEvts.events.map((i, idx) => <EventCard copied={copied} setCopied={setCopied} event={i} idx={idx} key={i.eventId + "eventPoint" + idx} />)
      )}
    </IonGrid>
  );
}

export default ListEvents;
