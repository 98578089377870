import React from "react";
import { IonButton, IonCol, IonGrid, IonItem, IonList, IonRow, IonSpinner, IonText, useIonRouter, useIonViewWillEnter } from "@ionic/react";
import { IAnnouncement } from "app/variables";
import { OrgFieldDisplay } from "components/EventCard";
import dayjs from "dayjs";
import { Layout } from "pages/Page";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { analytics, getAnnouncementById } from "app/firebase";
import EvtPosterDisplay from "components/EvtPosterDisplay";
import CopyButton from "components/CopyButton";
import { logEvent } from "firebase/analytics";

export default function AnnouncementPage() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const router = useIonRouter();
  const [copied, setCopied] = useState("");
  // const [copied, setCopied] = useState(false);
  const [announcement, setAnnouncement] = useState<IAnnouncement | null | undefined>(undefined);

  const fetchAnnouncement = useCallback(async () => {
    try {
      const res = await getAnnouncementById(id);
      if (res) {
        setAnnouncement(res);
      } else {
        setAnnouncement(null);
      }
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  useIonViewWillEnter(() => {
    if (!id) return;
    fetchAnnouncement();
  }, [id, fetchAnnouncement]);

  useEffect(() => {
    if (announcement === null) {
      setTimeout(() => router.push("/announcements"), 3000);
    } else if (announcement) {
      logEvent(analytics, `page_view_${announcement.title}`);
    }
  }, [announcement]);

  const copyToClipboard = () => {
    if (!announcement) return;
    navigator.clipboard.writeText(`${announcement.title}\n${window.location.href}`);
    setCopied(announcement.id);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(""), 3000);
    }
  }, [copied]);

  const headerTxt = (ann: IAnnouncement) => {
    const name = ann.title;
    return `${t("m-announcements")} ${"- " + name}`;
  };

  return (
    <Layout name={announcement ? headerTxt(announcement) : t("m-announcements")} back>
      {announcement === null ? (
        <IonGrid>
          <IonRow>
            <IonCol size="12">{t("annNotFound")}</IonCol>
            <IonCol size="12">
              <IonSpinner />
              {t("redirectToHome")}
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : (
        announcement !== undefined && (
          <IonGrid>
            <AnnouncementContent announcement={announcement} color={"primary"} copied={copied} setCopied={setCopied} />
            {/* <br /> */}
            <IonRow>
              <IonCol>
                <IonButton
                  expand="block"
                  color="medium"
                  onClick={() => {
                    router.push("/announcements");
                  }}
                >
                  {t("back")}
                </IonButton>
              </IonCol>
              <CopyButton expand color={copied ? "success" : "primary"} copyFunction={() => (!copied ? copyToClipboard() : {})} text={copied ? t("copiedToClipboard") : t("copyAnnouncement")} />
            </IonRow>
          </IonGrid>
        )
      )}
    </Layout>
  );
}

interface AnnouncementContentProps {
  announcement: IAnnouncement;
  color?: string;
  copied?: string;
  setCopied?: (args0: string) => void;
}

export const AnnouncementContent = (props: AnnouncementContentProps) => {
  const { announcement } = props;
  const { t } = useTranslation();

  return (
    <IonList>
      <IonItem lines="none" style={{ transition: "all 0.8s" }}>
        <IonCol className="ion-text-center">
          <IonText>
            <h5>
              <b>{announcement.title}</b>
            </h5>
          </IonText>
        </IonCol>
      </IonItem>
      {announcement.img && !!announcement.img.length && <EvtPosterDisplay eventPoster={announcement.img} />}
      {!!announcement.description && (
        <IonItem lines="none" className="ion-margin-top">
          <IonText>
            <b>{`Description : `}</b>
            <p style={{ whiteSpace: "pre-wrap" }}>{`${announcement.description}`}</p>
          </IonText>
        </IonItem>
      )}

      {announcement.validTill && (
        <IonItem lines="none">
          <IonText>
            <b>{`Deadline : `}</b>
            {` ${dayjs(announcement.validTill).format("YYYY MMM DD - HH:mm")}`}
          </IonText>
        </IonItem>
      )}

      {announcement.organisation && <OrgFieldDisplay name={t("host") + " :"} org={announcement.organisation} />}

      {announcement.externalLink && (
        <IonItem lines="none">
          <IonText>
            <b>{`Website / external link : `}</b>
          </IonText>
          <IonText
            className="ion-margin-horizontal"
            style={{
              cursor: "pointer",
              textTransform: "lowercase",
              textDecoration: "underline",
            }}
            color="primary"
            onClick={() => {
              if (announcement.externalLink?.startsWith("http")) {
                window.open(announcement.externalLink);
              } else {
                window.open(`http://${announcement.externalLink}`);
              }
            }}
          >
            {announcement.externalLink}
          </IonText>
        </IonItem>
      )}

      {!!announcement.coOrg?.organizationName && <OrgFieldDisplay name={"Co-organiser : "} org={announcement.coOrg} />}
      {!!announcement.supportOrg?.organizationName && <OrgFieldDisplay name={"Support organisation : "} org={announcement.supportOrg} />}
      {!!announcement.funder && (
        <IonItem lines="none">
          <IonText>
            <b>{`Funder : `}</b>
            {announcement.funder}
          </IonText>
        </IonItem>
      )}

      <IonItem lines="none">
        <IonText>
          <b>{`Announcement date : `}</b>
          {dayjs(announcement.createdAt).format("YYYY MMM DD - HH:mm")}
        </IonText>
      </IonItem>
    </IonList>
  );
};
