import dayjs from "dayjs";

export const siteName = "搵・港人";
export const baseUrl = "https://find-hkers.co.uk";
export const logoLink = `https://firebasestorage.googleapis.com/v0/b/trafford-hongkongers.appspot.com/o/images%2Ffind-konger.png?alt=media`;
export const ogImage = `https://firebasestorage.googleapis.com/v0/b/trafford-hongkongers.appspot.com/o/images%2FogImage3.jpg?alt=media`;
export type CommunityType = "local" | "nationwide" | "regional";
export type RoleType = "super-admin" | "organiser" | "dev" | "guest";

export interface IOrgDetails {
  id: string;
  districts: string[];
  region: string[];
  organizationName: string;
  email1: string;
  email1_label: string;
  email2?: string;
  email2_label?: string;
  email3?: string;
  email3_label?: string;
  email4?: string;
  email4_label?: string;
  website?: string;
  website2?: string;
  website3?: string;

  facebook?: string;
  instagram?: string;
  twitter?: string;
  youtube?: string;
  eventbrite?: string;
  createdAt: Date;
  updatedAt: Date;
  communityType: CommunityType;
  logo?: string;

  whatsapp?: string;
  phone?: string;
  address?: string; // (text pre-wrap)
  signal?: string; // (a link)
  telegram?: string;

  description?: string; // (text pre-wrap)
}

export interface GUnit {
  streetNo: string;
  country: string;
  adminArea1: string;
  adminArea2: string;
  postalTown: string;
  route: string;
  postcode: string;
  formattedAddress?: string;
}

export interface IEventDetails {
  eventId: string;
  eventName: string;
  eventNameChi?: string;
  coOrg: IOrgDetails | null;
  supportOrg: IOrgDetails | null;
  funder: string;
  eventDates: EventDateFormat[];
  eventDateType: EventDateType;
  geolocation: { lat: number | null; lng: number | null };
  organisation: IOrgDetails | null;
  website?: string;
  eventAddress: GUnit;
  description: string;
  descriptionChi?: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: IStaff | null;
  lastDatetime: Date;
  eventPoster?: string[];
  cancelledAt?: Date | null;
  cancelAnnouncement?: string | null;
}
export interface IEventDetailsInput {
  eventId: string;
  eventName: string;
  eventNameChi?: string;
  coOrg: IOrgDetails | null;
  supportOrg: IOrgDetails | null;
  funder: string;
  eventDates: EventDateFormatInput[];
  eventDateType: EventDateType;
  geolocation: { lat: number | null; lng: number | null };
  organisation: IOrgDetails | null;
  website?: string;
  eventAddress: GUnit;
  description: string;
  descriptionChi?: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: IStaff | null;
  lastDatetime: Date;
  eventPoster?: string[];
  cancelledAt?: Date | null;
  cancelAnnouncement?: string | null;
}

export interface IIntEvent {
  eventId: string;
  eventName: string;
  eventNameChi?: string;
  coOrg: IOrgDetails | null;
  supportOrg: IOrgDetails | null;
  funder: string;
  eventDates: EventDateFormatInput[];
  eventDateType: EventDateType;
  organisation: IOrgDetails | null;
  website?: string;
  description: string;
  descriptionChi?: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: IStaff | null;
  lastDatetime: Date;
  eventPoster?: string[];
  meetingLink: string;
  cancelledAt?: Date | null;
  cancelAnnouncement?: string | null;
}

const dateStart = dayjs().startOf("D").toDate();
export const defaultNewDate: EventDateFormat = {
  start: dateStart,
  end: dateStart,
};

export const defaultLocationDetail: IEventDetails = {
  eventId: "",
  eventName: "",
  eventNameChi: "",
  // eventDates: [defaultNewDate],
  eventDateType: "single",
  geolocation: { lat: null, lng: null },
  organisation: null,
  website: "",
  eventAddress: {
    streetNo: "",
    country: "",
    adminArea1: "",
    adminArea2: "",
    postalTown: "",
    route: "",
    postcode: "",
  },
  description: "",
  descriptionChi: "",
  createdAt: new Date(),
  coOrg: null,
  supportOrg: null,
  funder: "",
  updatedAt: new Date(),
  updatedBy: null,
  lastDatetime: new Date(),
  eventDates: [],
  eventPoster: [],
};
const dateStartInput = dayjs().startOf("D").toDate().toISOString();
export const defaultNewDateInput: EventDateFormatInput = {
  start: dateStartInput,
  end: dateStartInput,
};

export const defaultLocationDetailInput: IEventDetailsInput = {
  eventId: "",
  eventName: "",
  eventDates: [defaultNewDateInput],
  eventDateType: "single",
  geolocation: { lat: null, lng: null },
  organisation: null,
  website: "",
  eventAddress: {
    streetNo: "",
    country: "",
    adminArea1: "",
    adminArea2: "",
    postalTown: "",
    route: "",
    postcode: "",
  },
  description: "",
  coOrg: null,
  supportOrg: null,
  funder: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  updatedBy: null,
  lastDatetime: new Date(),
};

export const defaultOrg: IOrgDetails = {
  id: "new",
  districts: [],
  region: [],
  organizationName: "",
  email1: "",
  email1_label: "General email",
  email2: "",
  email2_label: "",
  email3: "",
  email3_label: "",
  email4: "",
  email4_label: "",
  website: "",
  facebook: "",
  instagram: "",
  twitter: "",
  telegram: "",
  youtube: "",
  eventbrite: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  communityType: "local",
  logo: "",
};

export type EventDateType = "single" | "recurring" | "flexible" | "range";

export interface EventDateFormat {
  start: Date;
  end: Date;
}
export interface EventDateFormatInput {
  start: string;
  end: string;
}

export interface INotify {
  id: string;
  userIp: string;
  type: NotifyType;
  district: string;
  createdAt: Date;
}

export interface IContactDev {
  id: string;
  fromEmail: string;
  subject: string;
  message: string;
  userIp: string;
  needReply: boolean;
  createdAt: Date;
}
export interface IContactAdmin {
  id: string;
  admin: IStaff | null;
  subject: string;
  message: string;
  userIp: string;
  createdAt: Date;
}

export const defaultContactDev: IContactDev = {
  id: "",
  fromEmail: "",
  subject: "",
  message: "",
  userIp: "",
  needReply: false,
  createdAt: new Date(),
};
export const defaultContactAdmin: IContactAdmin = {
  id: "new",
  admin: null,
  subject: "",
  message: "",
  userIp: "",
  createdAt: new Date(),
};

type NotifyType = "No organization found";

export interface IStaff {
  id: string;
  email: string;
  communityType: CommunityType;
  district: string[];
  organisation: string[];
  role: RoleType; // create event / edit event for Organiser
  disabled: boolean; // disable all edit/create rights
  vcode?: {
    code: string;
    expire: Date;
  };
}

export interface IAnnouncement {
  id: string;
  title: string;
  description: string;
  img: string[];
  externalLink: string;
  createdAt: Date;
  validTill: Date | null;
  coOrg: IOrgDetails | null;
  supportOrg: IOrgDetails | null;
  organisation: IOrgDetails | null;
  funder: string;
}

export const defaultNewStaff: IStaff = {
  id: "",
  email: "",
  communityType: "local",
  district: [],
  organisation: [],
  role: "organiser",
  disabled: false,
};

export const allRoles: RoleType[] = ["dev", "super-admin", "organiser", "guest"];

export const orgCardInfo = {
  facebook: { name: "Facebook", icon: "logos:facebook" },
  instagram: { name: "Instagram", icon: "skill-icons:instagram" },
  whatsapp: { name: "Whatsapp", icon: "logos:whatsapp-icon" },
  telegram: { name: "Telegram", icon: "logos:telegram" },
  signal: { name: "Signal", icon: "logos:signal" },
  twitter: { name: "Twitter", icon: "logos:twitter" },
  youtube: { name: "YouTube", icon: "logos:youtube-icon" },
  eventbrite: { name: "Eventbrite", icon: "logos:eventbrite-icon" },
  website: { name: "Website", icon: "mdi:web" },
  website2: { name: "Website #2", icon: "mdi:web" },
  website3: { name: "Website #3", icon: "mdi:web" },
  email: { name: "Email", icon: "entypo:email" },
  email2: { name: "Email #2", icon: "entypo:email" },
  email3: { name: "Email #3", icon: "entypo:email" },
  email4: { name: "Email #4", icon: "entypo:email" },
  address: { name: "Address", icon: "mdi:address-marker" },
  description: { name: "Description", icon: "uiw:message" },
  districts: { name: "District(s)", icon: "emojione-monotone:letter-d" },
  region: { name: "Region", icon: "emojione-monotone:letter-r" },
};

export const preWrapTxt = {
  districts: orgCardInfo.districts.name,
  orgName: "Organisation name",
  description: orgCardInfo.description.name,
  address: orgCardInfo.address.name,
};

// SECURITY
// on submit create / edit event => trigger auto email notification to super-admin & creator

// Cloud function
// delete events after event ends

// About page + copyright disclaimer

// e.g. `All present and future rights to intellectual property
// including inventions and improvement, trademarks (whether registered or common law trademarks),
// patents, designs, copyright, and corresponding property rights under the laws of any jurisdiction.`

// image upload for event page

// Ask about event category
// i18n
// RSVP
// open gmail for this project
// Google analytics
// FB & Google login
