import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IOrgDetails } from "app/variables";

interface OrgState {
  allNWOrgs: IOrgDetails[];
}

const initialState: OrgState = {
  allNWOrgs: [],
};

export const orgSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setAllNWOrgs: (state: OrgState, action: PayloadAction<IOrgDetails[]>) => {
      state.allNWOrgs = action.payload;
    },
  },
});

export const selectAllNWOrgs = (state: RootState) => state.orgs;

// Action creators are generated for each case reducer function
export const { setAllNWOrgs } = orgSlice.actions;

export default orgSlice.reducer;
