export const EN = {
  hkerMap: "HKers in UK event map",
  lang: "切換至中文",
  "m-evt": "Events",
  "m-netEvt": "Net Events",
  "m-NewEvt": "New Event",
  "m-Control": "Event Control",
  "m-Org": "Organisations",
  "m-NewOrg": "New organisation",
  "m-OrgMan": "Org Management",
  "m-StaffMan": "Staff Management",
  "m-StaffEnq": "Staff Enquiry",
  "m-ContactDev": "Contact Dev Team",
  "m-ContactUs": "Contact Us",
  "m-ReadStaffEnq": "Read Staff Enquiries",
  "m-ReadDevTeamMsg": "Read Dev Team msg",
  "m-announcements": "Announcements",
  "m-newAnnouncement": "New Announcement",
  announcements: "Announcement",
  editAnnouncement: "Edit Announcement",
  event: "Event",
  resetPw: "Reset Password",
  editOrg: "Edit Organisation",
  host: "Host",
  date: "Date",
  time: "Time",
  address: "Address",
  eventDes: "Event description",
  noEvt: "No event yet",
  listView: "List",
  mapView: "Map",
  mapAngle: "Map angle",
  mapTilt: "Map tilt",
  listEvtSearch: "Event name / part of event address",
  partOfAdd: "Postcode / address",
  orgSearchMsg: "This will tell you the organization(s) in-charge of your searched place",
  showNationwideOrg: "Show nationwide organisations",
  search: "Search",
  nationwideOrg: "Nationwide Organisations",
  unknownAdrMsg: "Unknown address, please make sure it's an UK address.",
  specificAdrMsg: "Please try a more specific address",

  view: "View",
  visitEventPage: "Visit Event Page",
  visitAnnouncementPage: "Visit announcement page",
  copyEventDetails: "Copy Event Details",
  copyAnnouncement: "Copy announcement details",

  copyDetails: "Copy details",
  website: "Website",
  eventsMap: "Events Map",
  direction: "Direction",
  "event/meetingLink": "Event / Meeting Link",
  district: "District",
  regional: "Regional",
  back: "Back",
  generalEmail: "General Email",
  yourEmailAddress: "Your Email Address",
  subject: "Subject",
  message: "Message",
  "replyYou?": "Would you like us to reply to you?",
  yes: "Yes",
  no: "No",
  submit: "Submit",
  contactDevMsg1: "We understand it's always difficult to get the right person to solve your problems.",
  contactDevMsg2: "Don't worry, you're now messaging the core members of this site.",
  copiedToClipboard: "Copied to Clipboard!",
  coOrganisation: "Co-Organisation",
  supportOrganisation: "Support Organisation",
  funder: "Funder",
  organisationDetails: "Organisation Details",
  "district(s)": "District(s)",
  email: "Email",
  organisation: "Organisation Name",
  loading: "Loading...",
  evtNotFound: "Event not found",
  annNotFound: "Announcement not found",
  redirectToHome: "Redirecting you to home page",
  loadingMsg1: "Getting location address",
  loadingMsg2: "Getting region name",
  loadingMsg3: "Drawing boundaries in map",
  loadingMsg4: "Finalising",
  plsSelectAdr: "Please select address",
  noThx: "No, thanks",
  notify: "Notify",
  hideMap: "Hide map",
  showMap: "Show map",
  keepMePosted: "Keep me posted!",
  thankYouForYourSupport: "Thank you for your support! 💪🏻",
  cancel: "Cancel",
  confirm: "Confirm",
  duplicateSubscription: "You subscribed before, no worries 👍🏻",
  learnMoreInAnnouncementPage: "Learn more in announcement page",
  checkLater: "Check later",
  noAnnouncementYet: "No announcement yet",
};
