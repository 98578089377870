import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonModal, IonRow, IonSlide, IonSlides, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { getAllAnnouncements } from "app/firebase";
import { selectAllAnnouncements } from "app/slices/announcementSlices";
import { IAnnouncement, baseUrl } from "app/variables";
import { closeOutline } from "ionicons/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const localKey = "find-hkers-announcements";

function AnnouncementModal() {
  const [showAnn, setShowAnn] = useState(false);
  const [unreadAnn, setUnreadAnn] = useState<IAnnouncement[]>([]);
  const rdxAnn = useSelector(selectAllAnnouncements);
  const { t } = useTranslation();

  const fetch = useCallback(async () => {
    await getAllAnnouncements();
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const dontShowAnn = useCallback(() => {
    setUnreadAnn([]);
    setShowAnn(false);
  }, []);

  useEffect(() => {
    if (rdxAnn.announcements.length) {
      const userRead = localStorage.getItem(localKey);
      const unreadAnnArr: IAnnouncement[] = [];
      if (!userRead) {
        setUnreadAnn(rdxAnn.announcements);
        setShowAnn(true);
      } else if (userRead) {
        const localArray: string[] = JSON.parse(userRead);
        rdxAnn.announcements.forEach((i) => {
          if (!localArray.includes(i.id)) {
            unreadAnnArr.push(i);
          }
        });
        if (unreadAnnArr.length) {
          setUnreadAnn(unreadAnnArr);
          setShowAnn(true);
        } else {
          dontShowAnn();
        }
      } else {
        setUnreadAnn(rdxAnn.announcements);
        setShowAnn(true);
      }
    } else {
      dontShowAnn();
    }
  }, [rdxAnn.announcements, dontShowAnn]);

  const setToStorage = useCallback(async () => {
    const arr = rdxAnn.announcements.map((i) => i.id);
    localStorage.setItem(localKey, JSON.stringify(arr));
  }, [rdxAnn.announcements]);

  const handleDismiss = useCallback(async () => {
    await setToStorage();
    setUnreadAnn([]);
    setShowAnn(false);
  }, [setToStorage]);

  const handleReReoute = useCallback(
    (link: string) => {
      handleDismiss();
      window.location.replace(link);
    },
    [handleDismiss]
  );

  return (
    <>
      <IonModal isOpen={showAnn} onDidDismiss={handleDismiss} keepContentsMounted>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t("m-announcements")}</IonTitle>
            <IonButtons slot="end">
              <IonButton slot="icon-only" className="ion-no-margin" onClick={handleDismiss}>
                <IonIcon icon={closeOutline} size="large" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent style={{ paddingTop: 0 }}>
          <IonSlides pager={true}>
            {unreadAnn.map((ann) => (
              <IonSlide key={ann.id}>
                <IonGrid className="ion-padding" style={{ paddingTop: 0, paddingBottom: "40px" }}>
                  <IonRow>
                    <IonCol>
                      <h5>{ann.title}</h5>
                    </IonCol>
                  </IonRow>
                  {ann.img && !!ann.img.length ? (
                    <IonRow>
                      <IonCol>
                        <img src={ann.img[0]} style={{ maxHeight: "360px" }} />
                      </IonCol>
                    </IonRow>
                  ) : (
                    <IonRow>
                      <IonCol className="font" style={{ whiteSpace: "pre-wrap", textAlign: "left" }}>
                        <IonText>{ann.description}</IonText>
                      </IonCol>
                    </IonRow>
                  )}
                  <IonRow>
                    <IonCol size="6" style={{ textOverflow: "ecllipse", whiteSpace: "pre-wrap", textAlign: "left" }} className="font">
                      <IonButton color="medium" expand="block" onClick={handleDismiss}>
                        {t("checkLater")}
                      </IonButton>
                    </IonCol>
                    <IonCol size="6" style={{ textOverflow: "ecllipse", whiteSpace: "pre-wrap", textAlign: "left" }} className="font">
                      <IonButton expand="block" onClick={() => handleReReoute(`${baseUrl}/announcement/${ann.id}`)}>
                        {t("learnMoreInAnnouncementPage")}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonSlide>
            ))}
          </IonSlides>
        </IonContent>
      </IonModal>
    </>
  );
}

export default AnnouncementModal;
