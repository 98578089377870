import React, { useCallback, useEffect, useState } from "react";
import { IonAccordion, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCol, IonItem, IonList, IonText } from "@ionic/react";
import { IOrgDetails, orgCardInfo, preWrapTxt } from "app/variables";
import { arrToStr, handleDistName } from "helpers/arrayToString";
import { InfoFields } from "./InfoFields";
import { copyToClipboard } from "helpers/copyToClipboard";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

type CardDisplayType = "accordion" | "card";

export const OrgCardContent = ({ org, showName, displayType = "accordion" }: { org: IOrgDetails; showName?: boolean; displayType: CardDisplayType }) => {
  const renderContent = (
    <>
      {showName && <InfoFields name={preWrapTxt.orgName} value={org.organizationName} />}
      {!!org.districts.length && <InfoFields name={preWrapTxt.districts} value={arrToStr(org.districts.map((i) => handleDistName(i)))} />}
      {!!org.region.length && <InfoFields name={orgCardInfo.region.name} value={arrToStr(org.region.map((i) => handleDistName(i)))} />}
      {!!org.phone && <InfoFields name={"Phone"} value={org.phone} />}
      {!!org.whatsapp && <InfoFields name={orgCardInfo.whatsapp.name} value={org.whatsapp} />}
      {!!org.signal && <InfoFields name={orgCardInfo.signal.name} value={org.signal} />}
      {!!org.telegram && <InfoFields name={orgCardInfo.telegram.name} value={org.telegram} />}
      {!!org.address && <InfoFields name={preWrapTxt.address} value={org.address} />}
      {!!org.email1 && <InfoFields name={orgCardInfo.email.name} value={org.email1} tag={org.email1_label} />}
      {!!org.email2 && <InfoFields name={orgCardInfo.email2.name} value={org.email2} tag={org.email2_label} />}
      {!!org.email3 && <InfoFields name={orgCardInfo.email3.name} value={org.email3} tag={org.email3_label} />}
      {!!org.email4 && <InfoFields name={orgCardInfo.email3.name} value={org.email4} tag={org.email4_label} />}

      {!!org.facebook && <InfoFields name={orgCardInfo.facebook.name} value={org.facebook} />}
      {!!org.instagram && <InfoFields name={orgCardInfo.instagram.name} value={org.instagram} />}
      {!!org.twitter && <InfoFields name={orgCardInfo.twitter.name} value={org.twitter} />}
      {!!org.youtube && <InfoFields name={orgCardInfo.youtube.name} value={org.youtube} />}
      {!!org.eventbrite && <InfoFields name={orgCardInfo.eventbrite.name} value={org.eventbrite} />}
      {!!org.website && <InfoFields name={orgCardInfo.website.name} value={org.website} />}
      {!!org.website2 && <InfoFields name={orgCardInfo.website2.name} value={org.website2} />}
      {!!org.website3 && <InfoFields name={orgCardInfo.website3.name} value={org.website3} />}

      {!!org.description && <InfoFields name={preWrapTxt.description} value={org.description} />}
      <br />
    </>
  );
  return displayType === "accordion" ? (
    <IonList slot="content" lines="none">
      {renderContent}
    </IonList>
  ) : (
    <IonCardContent className="ion-no-padding">{renderContent}</IonCardContent>
  );
};

export const OrgCard = ({ org, displayType = "accordion" }: { org: IOrgDetails; displayType?: CardDisplayType }) => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  const handleCopy = useCallback(async (org: IOrgDetails) => {
    await copyToClipboard(org);
    setCopied(true);
  }, []);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  return displayType === "accordion" ? (
    <IonAccordion key={org.id} value={org.id}>
      <IonItem slot="header">
        <IonText>{org.organizationName}</IonText>
      </IonItem>
      <OrgCardContent org={org} displayType={displayType} />
      <IonItem slot="content" lines="none" className="ion-padding-bottom">
        {!copied ? (
          <IonButton onClick={() => handleCopy(org)} size="default">
            <Icon icon="ph:copy" color="#FFF" fontSize={24} />
            <IonText className="ion-margin-horizontal">{t("copyDetails")}</IonText>
          </IonButton>
        ) : (
          <IonButton disabled>
            <Icon icon="teenyicons:tick-circle-outline" color="#1fcf58" fontSize={24} className="mr-1" />
            {t("copiedToClipboard")}
          </IonButton>
        )}
      </IonItem>
    </IonAccordion>
  ) : (
    <IonCard className="ion-no-padding">
      <IonCardHeader className="ion-no-padding">
        <IonItem lines="none">
          <IonCol>
            <h5>
              <b>{`${org.organizationName}`}</b>
            </h5>
          </IonCol>
          <IonButtons slot="end">
            {!copied ? (
              <IonButton slot="icon-only" onClick={() => handleCopy(org)}>
                <Icon icon="ph:copy" color="#555" fontSize={24} />
              </IonButton>
            ) : (
              <IonButton slot="icon-only" disabled>
                <Icon icon="teenyicons:tick-circle-outline" color="#1fcf58" fontSize={24} className="mr-1" />
                {t("copiedToClipboard")}
              </IonButton>
            )}
          </IonButtons>
        </IonItem>
      </IonCardHeader>
      <OrgCardContent org={org} displayType={displayType} />
    </IonCard>
  );
};
