import React from "react";
import { IonButton, IonCard, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonIcon, IonItem, IonList, IonLoading, IonRow, IonText, useIonRouter } from "@ionic/react";
import dayjs from "dayjs";
import { chevronUpCircle } from "ionicons/icons";
// import { Overlay } from "pigeon-maps";
import { OrgFieldDisplay } from "components/EventCard";
import i18n from "i18n";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { analytics, getAllIntEvts } from "../app/firebase";
import { IIntEvent } from "../app/variables";
import { Layout } from "../pages/Page";
// import { useSelector } from "react-redux";
// import { RootState } from "app/store";
import CopyButton from "components/CopyButton";
import EvtDateDisplay from "components/EvtDateDisplay";
import EvtPosterDisplay from "components/EvtPosterDisplay";
import { logEvent } from "firebase/analytics";

// const helmet = { title: siteName, description: "英國港人網上活動", og: { image: logoLink, url: window.location.href } };

function NetEvents() {
  const { t } = useTranslation();
  const [intEvents, setIntEvents] = useState<IIntEvent[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState("");
  const contentRef = useRef<HTMLIonContentElement>(null);
  const timeoutRef = useRef<any>(null);

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getAllIntEvts();
      if (res && res.msg === "success" && !!res.data.length) {
        setIntEvents(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetch();
    logEvent(analytics, "page_view_Net_events");
  }, [handleFetch]);

  useEffect(() => {
    if (copied) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => setCopied(""), 3000);
    }
  }, [copied]);

  return (
    <Layout name={t("m-netEvt")}>
      <IonContent ref={contentRef}>
        <IonGrid>
          {intEvents.map((i, idx) => (
            <NetEventCard event={i} color={copied === i.eventId ? "success" : "primary"} setCopied={setCopied} key={i.eventId + "intEvent" + idx} />
          ))}
        </IonGrid>
        <IonFab slot="fixed" vertical="bottom" horizontal="end" className="ion-margin ion-padding">
          <IonFabButton onClick={() => contentRef.current?.scrollToTop(600)}>
            <IonIcon icon={chevronUpCircle} />
          </IonFabButton>
        </IonFab>
        <br />
        <br />
        <br />
        <br />
        <br />
        {/* <OrgModal /> */}
      </IonContent>
      <IonLoading isOpen={isLoading} message={`${t("loading")}`} />
    </Layout>
  );
}

export default NetEvents;

interface NetEventCardProps {
  event: IIntEvent;
  color?: string;
  setCopied: (args: string) => void;
  editButton?: () => void;
  deleteButton?: (args0: IIntEvent) => void;
}

export const NetEventCard = (props: NetEventCardProps) => {
  const { t } = useTranslation();
  const router = useIonRouter();

  const { event, color = "primary", setCopied } = props;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${event.eventName} - ${dayjs(event.eventDates[0].start).format("YYYY-MM-DD HH:mm")}\n${event.meetingLink}\nEvent link: ${window.location.href}/${event.eventId}`);
    setCopied(event.eventId);
  };

  return (
    <>
      <IonCard id={event.eventId} className="ion-padding-horizontal" style={{ transition: "all 0.8s" }}>
        <IonGrid>
          <IonList>
            <IonItem button lines="none" style={{ transition: "all 0.8s" }} onClick={() => router.push(`/net-events/${event.eventId}`)}>
              <IonCol className="ion-text-center">
                <IonText style={{ textWrap: "pretty" }}>
                  <h5>{i18n.language === "en" ? <b>{event.eventName}</b> : <b>{event.eventNameChi ? event.eventNameChi : event.eventName}</b>}</h5>
                </IonText>
              </IonCol>
            </IonItem>
            {event.eventPoster && !!event.eventPoster.length && <EvtPosterDisplay eventPoster={event.eventPoster} />}
            {event.organisation && <OrgFieldDisplay name={t("host") + " :"} org={event.organisation} />}
            <EvtDateDisplay eventDateType={event.eventDateType} eventDates={event.eventDates} />
            <IonRow>
              <IonCol size="12" className="ion-padding-horizontal">
                <IonText color="dark">
                  <b>{t("event/meetingLink")}:</b>
                </IonText>
              </IonCol>
              <IonCol className="ion-padding-horizontal">
                <IonText
                  style={{
                    cursor: "pointer",
                    textTransform: "lowercase",
                    textDecoration: "underline",
                  }}
                  color="primary"
                  onClick={() => {
                    if (event.website?.startsWith("http")) {
                      window.open(event.website);
                    } else {
                      window.open(`http://${event.website}`);
                    }
                  }}
                >
                  {event.meetingLink}
                </IonText>
              </IonCol>
            </IonRow>
            {!!event.website && (
              <IonItem lines="none">
                <IonText>
                  <b>{t("website")}:</b>
                </IonText>
                <IonText
                  className="ion-margin-horizontal"
                  style={{
                    cursor: "pointer",
                    textTransform: "lowercase",
                    textDecoration: "underline",
                  }}
                  color="primary"
                  onClick={() => {
                    if (event.website?.startsWith("http")) {
                      window.open(event.website);
                    } else {
                      window.open(`http://${event.website}`);
                    }
                  }}
                >
                  {event.website}
                </IonText>
              </IonItem>
            )}
            {!!event.description && (
              <IonItem lines="none" className="ion-margin-top">
                <IonText>
                  <b>{t("eventDes") + " :"}</b>
                  {i18n.language === "en" ? (
                    <p style={{ whiteSpace: "pre-wrap" }}>{`${event.description}`}</p>
                  ) : (
                    <p style={{ whiteSpace: "pre-wrap" }}>{`${event.descriptionChi ? event.descriptionChi : event.description}`}</p>
                  )}
                </IonText>
              </IonItem>
            )}
            {!!event.coOrg?.organizationName && <OrgFieldDisplay name={"Co-organiser:"} org={event.coOrg} />}
            {!!event.supportOrg?.organizationName && <OrgFieldDisplay name={"Support organisation:"} org={event.supportOrg} />}
          </IonList>

          <IonRow>
            <IonCol className="ion-text-end">
              <IonButton expand="block" size="default" onClick={() => router.push(`/net-events/${event.eventId}`)}>
                {t("visitEventPage")}
              </IonButton>
            </IonCol>
            <CopyButton expand color={color} copyFunction={() => (color === "primary" ? copyToClipboard() : {})} text={color === "primary" ? t("copyEventDetails") : t("copiedToClipboard")} />
          </IonRow>
          <br />
        </IonGrid>
      </IonCard>
    </>
  );
};
