import { IonItem, IonButton, IonButtons, IonIcon, IonText } from "@ionic/react";
import { setFocusMapEvent } from "app/slices/eventSlices";
import { listOutline, mapOutline } from "ionicons/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

export interface IListMapProps {
  view: "list" | "map";
  setView: (args0: "list" | "map") => void;
}

function ListMapSwitch(props: IListMapProps) {
  const { view, setView } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSwitch = (viewType: "list" | "map") => {
    setView(viewType);
    dispatch(setFocusMapEvent(null));
  };

  return (
    <IonItem slot="end" lines="none">
      {/* <IonButton
        className="ion-no-margin"
        color={view === "list" ? "primary" : "light"}
        expand="block"
        onClick={() => handleSwitch("list")}
        style={{ height: "80%", transform: `scale(${view === "list" ? "1" : "0.8"})`, transition: "all 0.4s" }}
      >
        {t("listView")}
      </IonButton>
      <IonButton
        className="ion-no-margin"
        color={view === "map" ? "primary" : "light"}
        expand="block"
        onClick={() => handleSwitch("map")}
        style={{ height: "80%", transform: `scale(${view === "map" ? "1" : "0.8"})`, transition: "all 0.4s" }}
      >
        {t("mapView")}
      </IonButton> */}

      <IonButtons>
        <IonButton color={view === "list" ? "primary" : undefined} fill={view === "list" ? "solid" : undefined} onClick={() => handleSwitch("list")}>
          <IonText style={{ marginRight: "8px" }}>{t("listView")}</IonText>
          <IonIcon icon={listOutline} />
        </IonButton>
        <IonButton color={view === "map" ? "primary" : undefined} fill={view === "map" ? "solid" : undefined} onClick={() => handleSwitch("map")}>
          <IonText style={{ marginRight: "8px" }}>{t("mapView")}</IonText> <IonIcon icon={mapOutline} />
        </IonButton>
      </IonButtons>
    </IonItem>
  );
}

export default ListMapSwitch;
