import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IEventDetails, IIntEvent, IOrgDetails } from "app/variables";

export interface IGeoLocation {
  lng: number;
  lat: number;
}

interface EventsState {
  events: IEventDetails[];
  netEvts: IIntEvent[];
  filteredEvents: IEventDetails[];
  userLocation: IGeoLocation | null;
  focusMapEvent: IEventDetails | null;
  viewOrg: IOrgDetails | null;
}

const initialState: EventsState = {
  events: [],
  netEvts: [],
  filteredEvents: [],
  userLocation: null,
  focusMapEvent: null,
  viewOrg: null,
};

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setAllEvents: (state: EventsState, action: PayloadAction<IEventDetails[]>) => {
      state.events = action.payload;
    },
    setAllNetEvents: (state: EventsState, action: PayloadAction<IIntEvent[]>) => {
      state.netEvts = action.payload;
    },
    setFilteredEvents: (state: EventsState, action: PayloadAction<IEventDetails[]>) => {
      state.filteredEvents = action.payload;
    },
    setUserLocation: (state: EventsState, action: PayloadAction<IGeoLocation>) => {
      state.userLocation = action.payload;
    },
    setFocusMapEvent: (state: EventsState, action: PayloadAction<IEventDetails | null>) => {
      state.focusMapEvent = action.payload;
    },
    setViewOrg: (state: EventsState, action: PayloadAction<IOrgDetails | null>) => {
      state.viewOrg = action.payload;
    },
  },
});

export const selectAllEvents = (state: RootState) => state.events;

// Action creators are generated for each case reducer function
export const { setAllEvents, setAllNetEvents, setFilteredEvents, setUserLocation, setFocusMapEvent, setViewOrg } = eventSlice.actions;

export default eventSlice.reducer;
