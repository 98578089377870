import { IonItem, IonText } from "@ionic/react";
import { EventDateFormat, EventDateFormatInput, EventDateType } from "app/variables";
import dayjs from "dayjs";
import { formatRecurringDates } from "helpers/date";
import { t } from "i18next";
import React from "react";

interface EvtDateDisplayProps {
  eventDateType: EventDateType;
  eventDates: EventDateFormat[] | EventDateFormatInput[];
}

function EvtDateDisplay(props: EvtDateDisplayProps) {
  const { eventDateType, eventDates } = props;

  return (
    <>
      {eventDateType === "single" ? (
        <>
          <IonItem lines="none">
            <IonText>
              <b>{`${t("date")} :`}</b>
              {` ${dayjs(eventDates[0].start).format("YYYY MMM DD")}`}
            </IonText>
          </IonItem>
          <IonItem lines="none">
            <IonText>
              <b>{`${t("time")} :`}</b>
              {` ${dayjs(eventDates[0].start).format("HH:mm")} ~ ${dayjs(eventDates[0].end).format("HH:mm")}`}
            </IonText>
          </IonItem>
        </>
      ) : eventDateType === "range" ? (
        <>
          <IonItem lines="none">
            <IonText>
              <b>{`${t("date")} :`}</b>
              {` ${dayjs(eventDates[0].start).format("YYYY MMM DD")} ~ ${dayjs(eventDates[0].end).format("MMM DD")}`}
            </IonText>
          </IonItem>
          <IonItem lines="none">
            <IonText>
              <b>{`${t("time")} :`}</b>
              {` ${dayjs(eventDates[0].start).format("HH:mm")} ~ ${dayjs(eventDates[0].end).format("HH:mm")}`}
            </IonText>
          </IonItem>
        </>
      ) : eventDateType === "recurring" ? (
        <>
          <IonItem lines="none">
            <IonText>
              <b>{`${t("date")} :`}</b>
              <>{` ${formatRecurringDates(eventDates)}`}</>
            </IonText>
          </IonItem>
          <IonItem lines="none">
            <IonText>
              <b>{`${t("time")} :`}</b>
              {` ${dayjs(eventDates[0].start).format("HH:mm")} ~ ${dayjs(eventDates[0].end).format("HH:mm")}`}
            </IonText>
          </IonItem>
        </>
      ) : (
        eventDateType === "flexible" &&
        eventDates.map((eDate, idx) => (
          <IonItem key={idx + eDate.toString()} className="mb-1">
            <IonText>
              <p>
                <b>{`${t("date")} #${idx + 1} :`}</b>
                {` ${dayjs(eDate.start).format("YYYY MMM D")}`}
              </p>
              <p>
                <b>{`${t("time")} :`}</b>
                {` ${dayjs(eDate.start).format("HH:mm")} ~ ${dayjs(eDate.end).format("HH:mm")}`}
              </p>
            </IonText>
          </IonItem>
        ))
      )}
    </>
  );
}

export default EvtDateDisplay;
