import { IonCol, IonButton, IonIcon, IonText } from "@ionic/react";
import { copyOutline } from "ionicons/icons";
import React from "react";

interface CopyButtonProps {
  copyFunction: VoidFunction;
  color: string;
  text: string;
  expand?: boolean;
}

function CopyButton(props: CopyButtonProps) {
  const { expand = false, copyFunction, color, text } = props;

  return (
    <IonCol>
      <IonButton
        expand={expand ? "block" : undefined}
        color={color}
        onClick={() => {
          if (color === "primary") {
            // copyToClipboard();
            copyFunction();
          }
        }}
      >
        <IonIcon icon={copyOutline} style={{ marginRight: "8px" }} />
        {/* <IonText>{color === "primary" ? t("copyEventDetails") : t("copiedToClipboard")}</IonText> */}
        <IonText>{text}</IonText>
      </IonButton>
    </IonCol>
  );
}

export default CopyButton;
