import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IAnnouncement } from "app/variables";

interface AnnouncementState {
  announcements: IAnnouncement[];
}

const initialState: AnnouncementState = {
  announcements: [],
};

export const announcementSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setAllAnnouncements: (state: AnnouncementState, action: PayloadAction<IAnnouncement[]>) => {
      state.announcements = action.payload;
    },
  },
});

export const selectAllAnnouncements = (state: RootState) => state.announcements;

// Action creators are generated for each case reducer function
export const { setAllAnnouncements } = announcementSlice.actions;

export default announcementSlice.reducer;
