export const ZH = {
  hkerMap: "英國港人組織及活動地圖",
  lang: "Switch to English",
  "m-evt": "活動",
  "m-netEvt": "網上活動",
  "m-NewEvt": "新活動",
  "m-Control": "活動管理",
  "m-Org": "港人機構搜尋",
  "m-NewOrg": "建立機構",
  "m-OrgMan": "機構管理",
  "m-StaffMan": "機構人員管理",
  "m-StaffEnq": "通知 Super admin",
  "m-ContactDev": "聯絡開發者",
  "m-ContactUs": "聯絡我們",
  "m-ReadStaffEnq": "Read Staff Enquiries",
  "m-ReadDevTeamMsg": "Read Dev Team msg",
  "m-announcements": "有嘢宣佈",
  "m-newAnnouncement": "建立新「有嘢宣佈」",
  announcements: "有嘢宣佈",
  editAnnouncement: "編輯「有嘢宣佈」",
  event: "活動",
  resetPw: "重置密碼",
  editOrg: "編輯機構資料",
  host: "主辦",
  date: "日期",
  time: "時間",
  address: "地點",
  eventDes: "活動描述",
  noEvt: "暫時未有活動",
  listView: "清單",
  mapView: "地圖",
  mapAngle: "左右角度",
  mapTilt: "上下角度",
  listEvtSearch: "活動名稱 / 部份地址",
  partOfAdd: "輸入Postcode或部份地址",
  orgSearchMsg: "根據您搜尋的地方找出相關的負責機構",
  showNationwideOrg: "顯示nationwide機構",
  search: "搜尋",
  nationwideOrg: "Nationwide 機構",
  unknownAdrMsg: "請確認輸入為英國地址",
  specificAdrMsg: "請輸入更仔細的地址",

  view: "檢視",
  visitEventPage: "活動頁面",
  visitAnnouncementPage: "「有嘢宣佈」頁面",
  copyEventDetails: "複製活動詳情",
  copyAnnouncement: "複製詳情",
  copyDetails: "複製資料",
  website: "網站",
  eventsMap: "活動地圖",
  direction: "睇下點去",
  "event/meetingLink": "活動/會議連結",
  district: "地區",
  regional: "區域",
  back: "返回",
  generalEmail: "主要電子郵件",
  yourEmailAddress: "您的電子郵件地址",
  subject: "主題",
  message: "訊息",
  "replyYou?": "您希望我們回覆您嗎？",
  yes: "要",
  no: "唔使",
  submit: "提交",
  contactDevMsg1: "我哋明白通常好難搵到適當嘅人去解答您嘅疑難",
  contactDevMsg2: "放心，會收到下面張form嘅人係本站嘅核心成員。",
  copiedToClipboard: "製已複",
  coOrganisation: "合作組織",
  supportOrganisation: "支持組織",
  funder: "資助方",
  organisationDetails: "組織詳情",
  "district(s)": "地區",
  email: "電子郵件",
  organisation: "組織",
  loading: "幫緊你...",
  evtNotFound: "搵唔到喎...",
  annNotFound: "搵唔到添...",
  redirectToHome: "帶緊你返主頁",
  loadingMsg1: "搵緊地址",
  loadingMsg2: "搵埋地區",
  loadingMsg3: "畫緊地圖，等等...",
  loadingMsg4: "就得，幫緊你幫緊你...",
  plsSelectAdr: "搵到多於 1 個結果，麻煩揀返個啱嘅地址",
  noThx: "不了",
  notify: "通知",
  hideMap: "隱藏地圖",
  showMap: "顯示地圖",
  keepMePosted: "有update話我知",
  thankYouForYourSupport: "感謝支持～ 💪🏻 一有更新會email通知你 🙇🏻",
  cancel: "取消",
  confirm: "確定",
  duplicateSubscription: "你之前subscribe咗㗎喇，放心，小弟唔會忘記您 👍🏻",
  learnMoreInAnnouncementPage: "入嚟睇下",
  checkLater: "等陣先睇",
  noAnnouncementYet: "未有嘢宣佈 😅",
};
