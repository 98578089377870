import { IOrgDetails } from "app/variables";
import { arrToStr } from "./arrayToString";

export const copyToClipboard = async (org: IOrgDetails) => {
  let msg = `*Organisation Name: ${org.organizationName}*`;
  if (org.districts.length) {
    msg = msg + `\nDistrict(s): ${arrToStr(org.districts)}`;
  }
  if (org.region.length) {
    msg = msg + `\nRegion: ${arrToStr(org.region)}`;
  }

  // ====== CONTACTS ====== //
  if (org.phone || org.whatsapp || org.signal || org.telegram || org.address) {
    msg = msg + `\n\n*Contact*`;
  }
  if (org.phone) {
    msg = msg + `\nPhone: ${org.phone}`;
  }
  if (org.whatsapp) {
    msg = msg + `\nWhatsapp: ${org.whatsapp}`;
  }
  if (org.signal) {
    msg = msg + `\nSignal: ${org.signal}`;
  }
  if (org.telegram) {
    msg = msg + `\nTelegram: ${org.telegram}`;
  }
  if (org.address) {
    msg = msg + `\nAddress: ${org.address}`;
  }
  if (org.email1) {
    const emailLabel = org.email1_label ? org.email1_label : "General email";
    msg = msg + `\n${emailLabel}: ${org.email1}`;
  }
  if (org.email2) {
    const emailLabel = org.email2_label ? org.email2_label : "Email #2";
    msg = msg + `\n${emailLabel}: ${org.email2}`;
  }
  if (org.email3) {
    const emailLabel = org.email3_label ? org.email3_label : "Email #3";
    msg = msg + `\n${emailLabel}: ${org.email3}`;
  }
  if (org.email4) {
    const emailLabel = org.email4_label ? org.email4_label : "Email #4";
    msg = msg + `\n${emailLabel}: ${org.email4}`;
  }

  // ====== SOCIAL MEDIA ====== //
  if (org.facebook || org.instagram || org.twitter || org.youtube || org.website || org.website2 || org.website3) {
    msg = msg + `\n\n*Social Media*`;
  }
  if (org.facebook) {
    msg = msg + `\nFacebook: ${org.facebook}`;
  }
  if (org.instagram) {
    msg = msg + `\nInstagram: ${org.instagram}`;
  }
  if (org.twitter) {
    msg = msg + `\nTwitter: ${org.twitter}`;
  }
  if (org.youtube) {
    msg = msg + `\nYouTube: ${org.youtube}`;
  }
  if (org.eventbrite) {
    msg = msg + `\nEventbrite: ${org.eventbrite}`;
  }
  if (org.website) {
    msg = msg + `\nWebsite: ${org.website}`;
  }
  if (org.website2) {
    msg = msg + `\nWebsite #2: ${org.website2}`;
  }
  if (org.website3) {
    msg = msg + `\nWebsite #3: ${org.website3}`;
  }

  if (org.description) {
    msg = msg + `\n\nDescription: ${org.description}`;
  }
  navigator.clipboard.writeText(msg);
};
