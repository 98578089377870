import React from "react";
import { IonButton, IonButtons, IonCard, IonCardHeader, IonCol, IonIcon, IonItem, IonLabel, IonList, IonRow, IonText, useIonRouter } from "@ionic/react";
import { setFocusMapEvent, setViewOrg } from "app/slices/eventSlices";
import dayjs from "dayjs";
import i18n from "i18n";
import { mapSharp } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IEventDetails, IOrgDetails } from "../app/variables";
import { formatRecurringDates } from "../helpers/date";
import gMap from "../media/gMapLogo.png";
import { adrObjToStr } from "helpers/address";
import EvtPosterDisplay from "./EvtPosterDisplay";
import CopyButton from "./CopyButton";

interface EventCardProps {
  event: IEventDetails;
  idx: number;
  color?: string;
  editButton?: () => void;
  deleteButton?: (args0: IEventDetails) => void;
  copied: string;
  setCopied: (args0: string) => void;
}

export const EventCard = (props: EventCardProps) => {
  const { t } = useTranslation();
  const { event, idx, color = "" } = props;
  const dispatch = useDispatch();
  const router = useIonRouter();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${window.location.href}/${event.eventId}\n${event.eventName} - ${dayjs(event.eventDates[0].start).format("YYYY-MM-DD")}`);
    props.setCopied(event.eventId);
  };

  return (
    <>
      <IonCard id={event.eventId} color={color} style={{ transition: "all 0.8s" }}>
        <IonCardHeader>
          <IonItem button className="ion-no-padding" lines="none" color={color} style={{ transition: "all 0.8s" }} onClick={() => router.push(`/events/${event.eventId}`)}>
            <IonLabel className="ion-text-center" style={{ textWrap: "pretty" }}>
              <h2>{i18n.language === "en" ? <b>{event.eventName}</b> : <b>{event.eventNameChi ? event.eventNameChi : event.eventName}</b>}</h2>
            </IonLabel>
          </IonItem>
          <IonRow>
            <IonCol>
              <IonButtons className="flex-row-around">
                <IonButton onClick={() => dispatch(setFocusMapEvent(event))} size="small">
                  <IonText className="ion-margin">{t("eventsMap")}</IonText>
                  <IonIcon icon={mapSharp} color="primary" />
                </IonButton>
                <IonButton onClick={() => window.open(`https://www.google.com/maps?saddr=My+Location&daddr=${event.geolocation.lat},${event.geolocation.lng}`)} size="small">
                  <IonText className="ion-margin">{t("direction")}</IonText>
                  <img src={gMap} alt={gMap} style={{ maxWidth: "20px" }} />
                </IonButton>
              </IonButtons>
            </IonCol>
          </IonRow>
        </IonCardHeader>
        {event.eventPoster && !!event.eventPoster.length && <EvtPosterDisplay eventPoster={event.eventPoster} />}
        <IonList key={event.eventId + "incident" + idx}>
          {event.organisation && <OrgFieldDisplay name={t("host") + " :"} org={event.organisation} />}
          {event.eventDateType === "single" && (
            <>
              <IonItem lines="none">
                <IonText>
                  <b>{`${t("date")} :`}</b>
                  {` ${dayjs(event.eventDates[0].start).format("YYYY MMM DD")}`}
                </IonText>
              </IonItem>
              <IonItem lines="none">
                <IonText>
                  <b>{`${t("time")} :`}</b>
                  {` ${dayjs(event.eventDates[0].start).format("HH:mm")} ~ ${dayjs(event.eventDates[0].end).format("HH:mm")}`}
                </IonText>
              </IonItem>
            </>
          )}
          {event.eventDateType === "range" && (
            <>
              <IonItem lines="none">
                <IonText>
                  <b>{`${t("date")} :`}</b>
                  {` ${dayjs(event.eventDates[0].start).format("YYYY MMM DD")} ~ ${dayjs(event.eventDates[0].end).format("MMM DD")}`}
                </IonText>
              </IonItem>
              <IonItem lines="none">
                <IonText>
                  <b>{`${t("time")} :`}</b>
                  {` ${dayjs(event.eventDates[0].start).format("HH:mm")} ~ ${dayjs(event.eventDates[0].end).format("HH:mm")}`}
                </IonText>
              </IonItem>
            </>
          )}
          {event.eventDateType === "recurring" && (
            <>
              <IonItem lines="none">
                <IonText>
                  <b>{`${t("date")} :`}</b>
                  <>{` ${formatRecurringDates(event.eventDates)}`}</>
                </IonText>
              </IonItem>
              <IonItem lines="none">
                <IonText>
                  <b>{`${t("time")} :`}</b>
                  {` ${dayjs(event.eventDates[0].start).format("HH:mm")} ~ ${dayjs(event.eventDates[0].end).format("HH:mm")}`}
                </IonText>
              </IonItem>
            </>
          )}
          {event.eventDateType === "flexible" && (
            <>
              {event.eventDates.map((eDate, idx) => (
                <IonItem key={idx + eDate.toString()} className="mb-1">
                  <IonText>
                    <p>
                      <b>{`${t("date")} #${idx + 1} :`}</b>
                      {` ${dayjs(eDate.start).format("YYYY MMM D")}`}
                    </p>
                    <p>
                      <b>{`${t("time")} :`}</b>
                      {` ${dayjs(eDate.start).format("HH:mm")} ~ ${dayjs(eDate.end).format("HH:mm")}`}
                    </p>
                  </IonText>
                </IonItem>
              ))}
            </>
          )}
          <IonItem lines="none">
            <IonText>
              <b>{`${t("address")} :`}</b>
              {adrObjToStr(event.eventAddress)}
            </IonText>
          </IonItem>
          {!!event.website && (
            <IonItem lines="none">
              <IonText>
                <b>{t("website")}:</b>
              </IonText>
              <IonText
                className="ion-margin-horizontal"
                style={{
                  cursor: "pointer",
                  textTransform: "lowercase",
                  textDecoration: "underline",
                }}
                color="primary"
                onClick={() => {
                  if (event.website?.startsWith("http")) {
                    window.open(event.website);
                  } else {
                    window.open(`http://${event.website}`);
                  }
                }}
              >
                {event.website}
              </IonText>
            </IonItem>
          )}
          {!!event.description && (
            <IonItem lines="none">
              <IonText>
                <b>{t("eventDes") + " :"}</b>
                {i18n.language === "en" ? (
                  <p style={{ whiteSpace: "pre-wrap" }}>{`${event.description}`}</p>
                ) : (
                  <p style={{ whiteSpace: "pre-wrap" }}>{`${event.descriptionChi ? event.descriptionChi : event.description}`}</p>
                )}
              </IonText>
            </IonItem>
          )}
          {!!event.coOrg?.organizationName && <OrgFieldDisplay name={"Co-organiser:"} org={event.coOrg} />}
          {!!event.supportOrg?.organizationName && <OrgFieldDisplay name={"Support organisation:"} org={event.supportOrg} />}
        </IonList>
        <IonRow>
          <IonCol className="ion-text-end">
            <IonButton expand="block" size="default" color={color} onClick={() => router.push(`/events/${event.eventId}`)}>
              {t("visitEventPage")}
            </IonButton>
          </IonCol>
          <CopyButton
            expand
            color={props.copied !== event.eventId ? "primary" : "success"}
            copyFunction={() => (props.copied !== event.eventId ? copyToClipboard() : {})}
            text={props.copied !== event.eventId ? t("copyEventDetails") : t("copiedToClipboard")}
          />
        </IonRow>
        <br />
      </IonCard>
    </>
  );
};

// interface DateDisplayProps {
//   dates: { start: Date; end: Date }[];
//   eventDateType: EventDateType;
// }

// export const DateDisplay = (props: DateDisplayProps) => {
//   if (props.eventDateType === "single") {
//     return <ToolTipDisplay name={"Event Date:"} value={`${dayjs(props.dates[0].start).format("YYYY-MM-DD  HH:mm")}~${dayjs(props.dates[0].end).format("HH:mm")}`} />;
//   } else if (props.eventDateType === "recurring") {
//     let str = "";
//     props.dates.forEach((i, idx) => {
//       if (idx !== props.dates.length - 1) {
//         str = str.concat(dayjs(i.start).format("YYYY-MM-DD").toString()) + ", ";
//       } else {
//         str = str.concat(dayjs(i.start).format("YYYY-MM-DD").toString());
//       }
//     });
//     return (
//       <>
//         <ToolTipDisplay name={"Event Dates:"} value={str} />;
//         <ToolTipDisplay name={"Time:"} value={`${dayjs(props.dates[0].start).format("HH:mm")} - ${dayjs(props.dates[0].end).format("HH:mm")}`} />;
//       </>
//     );
//   } else if (props.eventDateType === "range") {
//     return (
//       <>
//         <ToolTipDisplay name={"Event Dates:"} value={props.dates.map((i) => dayjs(i.start).format(" YYYY-MM-DD")).toString()} />
//         <ToolTipDisplay name={"Time:"} value={`${dayjs(props.dates[0].start).format("HH:mm")} - ${dayjs(props.dates[0].end).format("HH:mm")}`} />;
//       </>
//     );
//   } else {
//     return (
//       <>
//         {props.dates.map((i, idx) => (
//           <ToolTipDisplay key={i.start.toLocaleString() + idx} name={`Day #${idx + 1}`} value={`${dayjs(i.start).format("YYYY-MM-DD HH:mm")}~${dayjs(i.end).format("HH:mm")}`} />
//         ))}
//       </>
//     );
//   }
// };

// interface ToolTipDisplayProps {
//   name: string;
//   value: string;
// }

// export const ToolTipDisplay = (props: ToolTipDisplayProps) => {
//   return (
//     <IonItem lines="none" className="ion-no-margin">
//       <IonCol className="ion-no-padding">
//         <b>{props.name}</b>
//         <IonText className="mx-1">{props.value}</IonText>
//       </IonCol>
//     </IonItem>
//   );
// };

interface OrgFieldDisplayProps {
  name: string;
  org: IOrgDetails;
}

export const OrgFieldDisplay = (props: OrgFieldDisplayProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <IonItem lines="none">
      <IonText style={{ textWrap: "balance" }}>
        <b>{props.name}</b>
        {" " + props.org.organizationName}
      </IonText>

      <IonButton className="mx-1" onClick={() => dispatch(setViewOrg(props.org))}>
        {t("view")}
      </IonButton>
    </IonItem>
  );
};
