import React, { useEffect } from "react";
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./App.css";
import Events, { OrgModal } from "pages/Events";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useJsApiLoader } from "@react-google-maps/api";
import Geocode from "react-geocode";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import Organisations from "pages/Organisations";
import NetEvents from "pages/NetEvents";
import IntEventPage from "pages/IntEventPage";
import EventPage from "pages/EventPage";
import ContactDev from "pages/ContactDev";
import AnnouncementListPage from "pages/AnnouncementListPage";
import AnnouncementPage from "pages/AnnouncementPage";
import AnnouncementModal from "components/AnnouncementModal";

setupIonicReact({
  mode: "md",
  animated: true,
});

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/London");

const App: React.FC = () => {
  const userLocation = useSelector((state: RootState) => state.events.userLocation);
  const { isLoaded } = useJsApiLoader({
    id: String(process.env.REACT_APP_API_ID),
    mapIds: [String(process.env.REACT_APP_MAP_ID)],
    googleMapsApiKey: String(process.env.REACT_APP_GEO_KEY),
  });
  useEffect(() => {
    if (!process.env.REACT_APP_GEO_KEY) return;
    Geocode.setApiKey(process.env.REACT_APP_GEO_KEY);
    Geocode.setLanguage("en");
    Geocode.setRegion("uk");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Switch>
              <Route path="/events" exact component={Events} />
              <Route path="/net-events" exact component={NetEvents} />
              <Route path="/contact-us" exact component={ContactDev} />
              <Route path="/events/:eventId" component={EventPage} />
              <Route path="/net-events/:eventId" component={IntEventPage} />
              <Route path="/announcements/" exact component={AnnouncementListPage} />
              <Route path="/announcement/:id" component={AnnouncementPage} />
              <Route path="/organisations" exact component={Organisations} />
              <Redirect to={"/events"} />
            </Switch>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
      <OrgModal />
      <AnnouncementModal />
    </IonApp>
  );
};

export default App;
