import { GUnit, defaultLocationDetail } from "../app/variables";

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
  formatted_address?: string;
}

export const getLongName = (data: AddressComponent[], formattedAdr?: string) => {
  const target: GUnit = { ...defaultLocationDetail.eventAddress };
  for (let i = 0; i < data.length; i++) {
    if (data[i].types[0].includes("postal_code")) {
      target.postcode = data[i].short_name;
    }
    if (data[i].types[0].includes("street_number")) {
      target.streetNo = data[i].long_name;
    }
    if (data[i].types[0].includes("route")) {
      target.route = data[i].short_name;
    }
    if (data[i].types[0].includes("postal_town")) {
      target.postalTown = data[i].long_name;
    }
    if (data[i].types[0].includes("administrative_area_level_2")) {
      target.adminArea2 = data[i].long_name;
    }
    if (data[i].types[0].includes("administrative_area_level_1")) {
      target.adminArea1 = data[i].long_name;
    }
    if (data[i].types[0].includes("country")) {
      target.country = data[i].long_name;
    }
    if (formattedAdr) {
      target.formattedAddress = formattedAdr;
    }
  }
  return target;
};

export const adrObjToStr = (data: GUnit) => {
  let str = "";
  if (data.streetNo) str += data.streetNo + ", ";
  if (data.route) str += data.route + ", ";
  if (data.postalTown) str += data.postalTown + ", ";
  if (data.adminArea2) str += data.adminArea2 + ", ";
  if (data.adminArea1) str += data.adminArea1 + ", ";
  if (data.country) data.postcode ? (str += data.country + ", ") : (str += data.country);
  if (data.postcode) str += data.postcode;
  return " " + str;
};
