export const arrToStr = (arr: string[]) => {
  return arr.toString().replaceAll(",", ", ");
};

export const dupCheck = (str: string, arr: string[]) => {
  if (arr.includes(str)) {
    arr.splice(arr.indexOf(str), 1);
    return arr;
  } else {
    arr.push(str);
    arr.sort((a, b) => {
      if (a > b) {
        return 1;
      } else return -1;
    });
    return arr;
  }
};

export const handleDistName = (str: string) => {
  if (!str.includes(",")) return str;
  if (str.includes("Armagh") || str.includes("Newry")) {
    return str.replaceAll(",", "");
  } else {
    return str.split(",")[1] + " " + str.split(",")[0];
  }
};

// District names that should not be inverted
//Armagh City, Banbridge and Craigavon
//Newry, Mourne and Down
