import { Icon } from "@iconify/react";
import { IonItem, IonLabel } from "@ionic/react";
import { orgCardInfo, preWrapTxt } from "app/variables";
import React from "react";

interface InfoFieldsProps {
  name: string;
  value: string;
  tag?: string;
}

const preWrapTxtArr = [preWrapTxt.districts, preWrapTxt.orgName, preWrapTxt.address, preWrapTxt.description];

export const InfoFields = (props: InfoFieldsProps) => {
  const findIdx = Object.values(orgCardInfo).findIndex((i) => i.name === props.name);
  return (
    <IonItem lines="none" button={props.name.includes("Whatsapp")} href={props.name.includes("Whatsapp") ? `https://wa.me/${props.value}` : undefined}>
      {props.name === "Phone" && <Icon icon="el:phone-alt" color={"#77CB6D"} fontSize={24} className="mr-1" />}
      {findIdx >= 0 && <Icon icon={Object.values(orgCardInfo)[findIdx].icon} fontSize={24} className="mr-1" />}
      <IonLabel>
        <h4>
          <b>{`${props.tag || props.name}:`}</b>
          <br />
          {props.name.includes("Email") ? (
            <a className="ion-text-wrap pointer" href={`mailto:${props.value}`}>
              {props.value}
            </a>
          ) : props.name.includes("Phone") ? (
            <a className="ion-text-wrap pointer" href={`tel:${props.value}`}>
              {props.value}
            </a>
          ) : props.name.includes("Whatsapp") ? (
            <a className="ion-text-wrap pointer">Send message</a>
          ) : preWrapTxtArr.includes(props.name) ? (
            <p style={{ whiteSpace: "pre-wrap" }}>{props.value}</p>
          ) : (
            <a color="tertiary" href={props.value}>
              {props.value}
            </a>
          )}
        </h4>
      </IonLabel>
      <br />
      {/* {!!props.tag && (
        <IonChip color={"primary"} style={{ pointerEvent: "none!important", cursor: "default" }}>
          {props.tag}
        </IonChip>
      )} */}
    </IonItem>
  );
};
