import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonModal,
  IonNote,
  IonRow,
} from "@ionic/react";
import React, { useCallback, useState } from "react";
import i18n from "i18n";
import { calendarOutline, globeOutline, heartCircleSharp, languageOutline, megaphoneOutline, searchOutline, sendOutline, sendSharp } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "./Menu.css";
import FindKonger from "media/find-konger.png";
import { ModalHeader } from "./ModalHeader";
import { postSubscription } from "app/firebase";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const Menu: React.FC = () => {
  const [subscribeModal, setSubscribeModal] = useState(false);
  const [subEmail, setSubEmail] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const handleCloseModal = useCallback(() => {
    setSubEmail("");
    setSubscribeModal(false);
  }, []);

  const handlePostSubscription = useCallback(async () => {
    if (!subEmail) return;
    try {
      const res = await postSubscription(subEmail);
      if (res === "success") {
        const msg = t("thankYouForYourSupport");
        setMessage(msg);
      }
      if (res === "duplicated") {
        const msg = t("duplicateSubscription");
        setMessage(msg);
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleCloseModal();
    }
  }, [t, subEmail]);

  const appPages: AppPage[] = [
    {
      title: t("m-evt"),
      url: "/events",
      iosIcon: calendarOutline,
      mdIcon: calendarOutline,
    },
    {
      title: t(`m-netEvt`),
      url: "/net-events",
      iosIcon: globeOutline,
      mdIcon: globeOutline,
    },
    {
      title: t(`m-announcements`),
      url: "/announcements",
      iosIcon: megaphoneOutline,
      mdIcon: megaphoneOutline,
    },
    {
      title: t("m-Org"),
      url: "/organisations",
      iosIcon: searchOutline,
      mdIcon: searchOutline,
    },
    {
      title: t("m-ContactUs"),
      url: "/contact-us",
      iosIcon: sendOutline,
      mdIcon: sendSharp,
    },
  ];
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>
            <img src={FindKonger} alt={FindKonger} style={{ maxWidth: "max(200px, 50%)" }} />
          </IonListHeader>
          <IonItem lines="none" className="ion-text-end">
            <IonCol className="ion-no-padding ion-margin-top">
              <IonNote>by Triologic Ltd</IonNote>
            </IonCol>
          </IonItem>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? "selected" : ""} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
        <IonButton color="medium" expand="block" fill="clear" onClick={() => setSubscribeModal(true)}>
          <IonIcon icon={heartCircleSharp} color="danger" slot="start" />
          <IonLabel slot="end">{t(`keepMePosted`)}</IonLabel>
        </IonButton>
        <IonButton
          color="medium"
          expand="block"
          fill="clear"
          onClick={() => {
            const newLang = i18n.language === "zh" ? "en" : "zh";
            i18n.changeLanguage(newLang);
            localStorage.setItem("lang", newLang);
          }}
        >
          <IonIcon icon={languageOutline} slot="start" />
          <IonLabel slot="end">{t(`lang`)}</IonLabel>
        </IonButton>
      </IonContent>
      {/* <IonFooter>
        <IonButton
          onClick={() => {
            if (window.navigator.geolocation) {
              window.navigator.permissions.query({ name: "geolocation" }).then((permissionStatus) => {
                if (permissionStatus.state === "denied") {
                  alert("Please allow location access.");
                  window.location.href = "app-settings:location";
                } else {
                  navigator.geolocation.getCurrentPosition((e) => dispatch(setUserLocation({ lng: e.coords.longitude, lat: e.coords.latitude })));
                }
              });
            } else {
              alert("Geolocation is not supported in your browser.");
            }
          }}
        >
          TEST GET LOCATION
        </IonButton>
      </IonFooter> */}
      {/* <IonFooter color="medium"> */}
      {/* <IonToolbar> */}

      {/* </IonToolbar> */}
      {/* </IonFooter> */}
      <IonModal mode="ios" isOpen={subscribeModal} onDidDismiss={handleCloseModal}>
        <ModalHeader title={t("keepMePosted")} closeButton closeFunction={handleCloseModal} />
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem className="ion-no-padding">
                  <IonLabel position="floating" className="font">
                    {t("email")}
                  </IonLabel>
                  <IonInput value={subEmail} onIonChange={(e) => setSubEmail(e.detail.value!)} />
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonRow>
            <IonCol>
              <IonButton expand="block" color="medium" onClick={handleCloseModal}>
                {t("cancel")}
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton expand="block" onClick={handlePostSubscription}>
                {t("confirm")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      </IonModal>
      <IonAlert message={message} isOpen={!!message} onDidDismiss={() => setMessage("")} buttons={[{ text: "OK", handler: () => setMessage("") }]} />
    </IonMenu>
  );
};

export default Menu;
