// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { addDoc, doc, getDoc, getFirestore, setDoc, where } from "firebase/firestore";
import { fromDB } from "../helpers/date";

import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { IAnnouncement, IContactDev, IEventDetails, IIntEvent, IOrgDetails } from "./variables";
import { store } from "./store";
import { setAllEvents } from "./slices/eventSlices";
import { setAllNWOrgs } from "./slices/orgSlices";
import axios from "axios";
import { getAnalytics } from "firebase/analytics";
import { setAllAnnouncements } from "./slices/announcementSlices";

// import { setAuthUser, setAuthUserIsInit } from "./slices/authSlice";
// import { store } from "./store";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_AREACT_APP_MESSAGE_SENDER_IDPP_,
  appId: process.env.REACT_APP_APP_ID,
};

export const collections = {
  contactDev: "contactDev",
  events: "events",
  intEvents: "int-events",
  notify: "notify",
  organizations: "organizations",
  staffEnquiries: "staffEnquiries",
  staffs: "staffs",
  subscriptions: "subscriptions",
  announcements: "announcements",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

// export const initFirebase = async () => {
//   if (getApps().length <= 0) {
//     // Initialize Firebase
//     const app = initializeApp(firebaseConfig);
//     // console.log(`New Firebase App initialised`);
//     const auth = getAuth(app);
//     onAuthStateChanged(auth, async (user) => {
//       // console.log("auth changed");
//       if (user) {
//         // User is signed in, see docs for a list of available properties
//         // https://firebase.google.com/docs/reference/js/firebase.User
//         const uid = user.uid;
//         const db = getFirestore();
//         const docRef = doc(db, "staffs", uid);
//         const ss = await getDoc(docRef);

//         if (ss.exists()) {
//           const unSub = onSnapshot(docRef, async (sp) => {
//             const data = fromDB(sp.data());
//             const id = sp.id;
//             const authStaff: IStaff = {
//               id,
//               ...data,
//             } as IStaff;
//             store.dispatch(setAuthUser(authStaff));
//             store.dispatch(setAuthUserIsInit(true));
//           });
//           // GlobalListener();
//         } else {
//           console.log("no auth user found in DB");
//           store.dispatch(setAuthUser(null));
//           store.dispatch(setAuthUserIsInit(true));
//         }
//       } else {
//         console.log("user signed out");
//         store.dispatch(setAuthUser(null));
//         store.dispatch(setAuthUserIsInit(true));
//       }
//     });
//   }
// };

export const isPresentEvt = (data: IEventDetails | IIntEvent) => {
  if (data.eventDates.some((i) => i.end > new Date() || i.start > new Date())) {
    return true;
  } else return false;
};

export const getAllEvts = async () => {
  // const q = query(collection(getFirestore(), collections.events), where("lastDatetime", ">=", Timestamp.fromMillis(new Date().valueOf())));
  const q = query(collection(getFirestore(), collections.events), where("lastDatetime", ">=", new Date()));
  try {
    const sss = await getDocs(q);
    const temp: IEventDetails[] = [];
    sss.forEach((i) => {
      const data = fromDB(i.data()) as IEventDetails;
      // if (isPresentEvt(data)) {
      temp.push({ ...data, eventId: i.id });
      // }
    });
    temp.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    store.dispatch(setAllEvents(temp));
    return "success";
  } catch (error) {
    console.error(error);
  }
};

export const getAllIntEvts = async () => {
  const q = query(collection(getFirestore(), collections.intEvents), where("lastDatetime", ">=", new Date()));
  try {
    const sss = await getDocs(q);
    const temp: IIntEvent[] = [];
    sss.forEach((i) => {
      const data = fromDB(i.data()) as IIntEvent;
      // if (isPresentEvt(data)) {
      temp.push({ ...data, eventId: i.id });
      // }
    });
    // store.dispatch(setAllNetEvents(temp));
    temp.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    return { msg: "success", data: temp };
  } catch (error) {
    console.error(error);
  }
};

export const getEvtById = async (eventId: string) => {
  const db = getFirestore();
  const docRef = doc(db, collections.events, eventId);
  try {
    const res = await getDoc(docRef);
    return fromDB(res.data()) as IEventDetails;
  } catch (error) {
    console.error(error);
  }
};

export const getIntEvtById = async (eventId: string) => {
  const db = getFirestore();
  const docRef = doc(db, collections.intEvents, eventId);
  try {
    const res = await getDoc(docRef);
    return fromDB(res.data()) as IIntEvent;
  } catch (error) {
    console.error(error);
  }
};

export const fetchAllNWOrgs = async () => {
  const db = getFirestore();
  const q = query(collection(db, collections.organizations), where("communityType", "==", "nationwide"), orderBy("organizationName", "asc"));
  try {
    const res = await getDocs(q);
    const temp: IOrgDetails[] = [];
    res.forEach((i) => {
      temp.push({ ...i.data(), id: i.id } as IOrgDetails);
    });
    store.dispatch(setAllNWOrgs(temp));
  } catch (error) {
    console.error(error);
  }
};

export const postSubscription = async (email: string) => {
  const db = getFirestore();

  try {
    const res = await getDoc(doc(db, `${collections.subscriptions}/${email}`));
    if (!res.exists()) {
      await setDoc(doc(db, `${collections.subscriptions}/${email}`), { email }, { merge: false });
    } else {
      return "duplicated";
    }
    return "success";
  } catch (error: any) {
    console.error(error);
    return error.code as string;
  }
};

export const reportError = async (contactMsg: Omit<IContactDev, "id">) => {
  const db = getFirestore();
  try {
    const res = await axios.get("https://api.ipify.org/?format=json");
    const userIp = res.data.ip;
    await addDoc(collection(db, collections.staffEnquiries), {
      admin: null,
      subject: contactMsg.subject,
      message: contactMsg.message,
      userIp,
      createdAt: new Date(),
    });
    await addDoc(collection(db, collections.contactDev), {
      ...contactMsg,
      userIp,
      createdAt: new Date(),
    });
    return "success";
  } catch (error: any) {
    console.error(error);
    return error.code as string;
  }
};

export const getAllAnnouncements = async () => {
  const q = query(collection(getFirestore(), collections.announcements), orderBy("createdAt", "desc"));
  try {
    const sss = await getDocs(q);
    const temp: IAnnouncement[] = [];
    sss.forEach((i) => {
      const data = fromDB(i.data());
      temp.push({ ...data, id: i.id } as IAnnouncement);
    });
    const filtered = temp.filter((i) => !i.validTill || i.validTill > new Date());
    filtered.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    store.dispatch(setAllAnnouncements(filtered));
    return { msg: "success", data: filtered };
  } catch (error) {
    console.error(error);
  }
};

export const getAnnouncementById = async (id: string) => {
  const db = getFirestore();
  const docRef = doc(db, collections.announcements, id);
  try {
    const res = await getDoc(docRef);
    return { ...fromDB(res.data()), id } as IAnnouncement;
  } catch (error) {
    console.error(error);
  }
};
