import { IonModal, IonContent, IonGrid, IonSearchbar, IonRow, IonAccordionGroup, IonCol } from "@ionic/react";
import React, { useState } from "react";
import BackFooter from "./BackFoorter";
import { ModalHeader } from "./ModalHeader";
import { OrgCard } from "./OrgCardContent";
import { useTranslation } from "react-i18next";
import { IOrgDetails } from "app/variables";

interface NWOrgModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  allNWOrgs: IOrgDetails[];
}

function NWOrgModal(props: NWOrgModalProps) {
  const { isOpen, onClose, allNWOrgs } = props;
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <ModalHeader title={`${t("nationwideOrg")}`} closeButton closeFunction={onClose} />
      <IonContent>
        <IonGrid>
          <IonRow style={{ position: "sticky", top: 0, zIndex: 1, background: "white" }}>
            <IonSearchbar
              showClearButton="always"
              onIonClear={() => {
                setSearchText("");
              }}
              placeholder={"Search by name / phone / email / whatsapp"}
              value={searchText}
              onIonChange={(e) => setSearchText(e.detail.value!)}
            />
          </IonRow>
          <IonRow>
            <IonCol>
              <IonAccordionGroup>
                {allNWOrgs
                  .filter(
                    (org) =>
                      org.organizationName.toLowerCase().includes(searchText.toLowerCase()) ||
                      (org.phone && org.phone.includes(searchText.toLowerCase())) ||
                      (org.email1 && org.email1.toLowerCase().includes(searchText.toLowerCase())) ||
                      (org.whatsapp && org.whatsapp.includes(searchText.toLowerCase()))
                  )
                  .map((i) => (
                    // <IonCol key={i.id} size="12">
                    <OrgCard key={i.id} org={i} displayType="accordion" />
                    // </IonCol>
                  ))}
              </IonAccordionGroup>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <BackFooter onClick={onClose} />
    </IonModal>
  );
}

export default NWOrgModal;
