import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import { EventDateFormat, EventDateFormatInput } from "../app/variables";

export const fromDB = (data: any) => {
  if (typeof data === "string") {
    return data;
  }
  const result = {};
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === `undefined` || !value) {
      Object.assign(result, {
        [key]: value,
      });
    } else if (value instanceof Timestamp) {
      Object.assign(result, {
        [key]: value.toDate(),
      });
    } else if (typeof value === `object`) {
      if (Array.isArray(value)) {
        const newValue: any[] = [];
        value.forEach((item) => {
          newValue.push(fromDB(item));
        });
        Object.assign(result, {
          [key]: newValue,
        });
      } else {
        Object.assign(result, {
          [key]: fromDB(value),
        });
      }
    } else {
      Object.assign(result, {
        [key]: value,
      });
    }
  }
  return result;
};

export const sortDatesAsc = async (dates: string[]) => {
  dates.sort((a, b) => {
    if (a > b) {
      return 1;
    } else return -1;
  });
  return dates;
};

export const formatRecurringDates = (dates: (EventDateFormat | EventDateFormatInput)[]) => {
  let dateString = "";
  dates.forEach((evt) => {
    if (!dateString) {
      dateString = dayjs(evt.start).format("YYYY MMM D");
    } else if (dateString.includes(dayjs(evt.start).format("MMM"))) {
      dateString += `, ${dayjs(evt.start).format("D")}`;
    } else {
      dateString += `, ${dayjs(evt.start).format("MMM D")}`;
    }
  });
  return dateString;
};
