import React, { useEffect } from "react";
import { IonAlert, IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonList, IonLoading, IonRow, IonText, IonTextarea } from "@ionic/react";
import { useCallback, useState } from "react";
import { IContactDev, defaultContactDev } from "../app/variables";
import "../css/ContactDev.css";
import { Layout } from "../pages/Page";
import { useTranslation } from "react-i18next";
import { analytics, reportError } from "app/firebase";
import { logEvent } from "firebase/analytics";

function ContactDev() {
  const [contactMsg, setContactMsg] = useState<IContactDev>(defaultContactDev);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const { id, ...data } = contactMsg;
    try {
      const res = await reportError(data);
      if (res === "success") {
        setContactMsg(defaultContactDev);
        setMessage("Thanks for your message!");
      }
    } catch (error: any) {
      setMessage("Oops! Something's wrong, please try again");
      console.error(error);
      return error.code;
    } finally {
      setIsLoading(false);
    }
  }, [contactMsg]);

  useEffect(() => {
    logEvent(analytics, "page_view_Contact_us");
  }, []);

  return (
    <Layout name={t("m-ContactUs")}>
      <IonGrid>
        <IonRow>
          <IonCol className="ion-padding-horizontal">
            <IonText>
              <p style={{ textAlign: "left" }}>{t("contactDevMsg1")}</p>
              <p style={{ textAlign: "left" }}>
                <b>{t("contactDevMsg2")}</b>
              </p>
            </IonText>
          </IonCol>
        </IonRow>
        <IonCard>
          <IonCardContent>
            <IonList>
              <CustomInput required title={t("subject")} value={contactMsg?.subject} field="subject" obj={contactMsg} onChange={setContactMsg} />
              <IonItem className="ion-no-padding">
                <IonLabel position="floating" className="font">
                  {t("message")} <span style={{ color: "var(--ion-color-danger)" }}>*</span>
                </IonLabel>
                <IonTextarea autoGrow value={contactMsg?.message} rows={6} onIonChange={(e) => setContactMsg({ ...contactMsg, message: e.detail.value! })} />
              </IonItem>
              <IonGrid className="ion-margin-vertical">
                <IonRow>
                  <IonCol className="flex-row-s-center">
                    <IonText>
                      <h2>{t("replyYou?")}</h2>
                    </IonText>
                  </IonCol>
                  <IonCol className="ion-text-end">
                    <div className="flex-column-end switchButtonContainer">
                      <div
                        className={`switchButton ${contactMsg.needReply ? "optionYes" : "optionNo"}`}
                        style={{ top: contactMsg.needReply ? "0px" : "-32px" }}
                        onClick={() => setContactMsg({ ...contactMsg, needReply: !contactMsg.needReply })}
                      >
                        {t("yes")}
                      </div>
                      <div
                        className={`switchButton ${contactMsg.needReply ? "optionYes" : "optionNo"}`}
                        style={{ top: contactMsg.needReply ? "32px" : "0px" }}
                        onClick={() => setContactMsg({ ...contactMsg, needReply: !contactMsg.needReply })}
                      >
                        {t("no")}
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
                {contactMsg.needReply && (
                  <IonRow>
                    <IonCol size="12">
                      <CustomInput required={contactMsg.needReply} title={t("yourEmailAddress")} value={contactMsg?.fromEmail} field="fromEmail" obj={contactMsg} onChange={setContactMsg} />
                    </IonCol>
                  </IonRow>
                )}
              </IonGrid>

              <IonRow>
                <IonCol>
                  <IonButton expand="block" disabled={(contactMsg.needReply && !contactMsg.fromEmail) || !contactMsg.subject || !contactMsg.message} onClick={handleSubmit}>
                    {t("submit")}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonGrid>
      <IonLoading isOpen={isLoading} message={"Loading"} />
      <IonAlert message={message} isOpen={!!message} buttons={[{ text: "Ok", handler: () => setMessage("") }]} />
    </Layout>
  );
}

export default ContactDev;

interface CustomInputProps {
  obj: IContactDev;
  field: keyof IContactDev;
  value: string;
  title: string;
  onChange: (args0: IContactDev) => void;
  required?: boolean;
}

export const CustomInput = (props: CustomInputProps) => {
  const { value, title, field, onChange, obj, required = false } = props;
  return (
    <IonItem className="ion-no-padding">
      <IonLabel position="floating" className="font">
        {title} {required && <span style={{ color: "var(--ion-color-danger)" }}>*</span>}
      </IonLabel>
      <IonInput value={value} onIonChange={(e) => onChange({ ...obj, [field]: e.detail.value! })} />
    </IonItem>
  );
};
