import React from "react";
import { ReactNode } from "react";
import { IonBackButton, IonButtons, IonContent, IonFooter, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useParams } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import "./Page.css";

const Page: React.FC = () => {
  const { name } = useParams<{ name: string }>();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer name={name} />
      </IonContent>
    </IonPage>
  );
};

export default Page;

interface LayoutProps {
  children: ReactNode;
  name: string;
  back?: boolean;
  extraButton?: ReactNode;
  footer?: ReactNode;
}

export const Layout = (props: LayoutProps) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">{!props.back ? <IonMenuButton /> : <IonBackButton />}</IonButtons>
          <IonTitle>{props.name}</IonTitle>
          {props.extraButton && props.extraButton}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen scrollEvents={false}>
        {props.children}
      </IonContent>
      {props.footer && <IonFooter>{props.footer}</IonFooter>}
    </IonPage>
  );
};
