import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";

export const ModalHeader = ({ title, closeButton, closeFunction }: { title: string; closeButton?: boolean; closeFunction?: () => void }) => {
  return (
    <IonHeader>
      <IonToolbar>
        <h5 className="ion-padding-horizontal">
          <b>{title}</b>
        </h5>
        {closeButton && (
          <IonButtons slot="end">
            <IonButton slot="icon-only" onClick={closeFunction}>
              <IonIcon icon={closeOutline} size="large" />
            </IonButton>
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};
