import React from "react";
import { IonButton, IonCol, IonGrid, IonItem, IonList, IonRow, IonSpinner, IonText, useIonRouter, useIonViewWillEnter } from "@ionic/react";
import { IEventDetails } from "app/variables";
import { OrgFieldDisplay } from "components/EventCard";
import dayjs from "dayjs";
import i18n from "i18n";
import { Layout } from "pages/Page";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { analytics, getEvtById } from "app/firebase";
import EvtPosterDisplay from "components/EvtPosterDisplay";
import EvtDateDisplay from "components/EvtDateDisplay";
import CopyButton from "components/CopyButton";
import { logEvent } from "firebase/analytics";

function EventPage() {
  const { eventId } = useParams<{ eventId: string }>();
  const { t } = useTranslation();
  const router = useIonRouter();
  const [copied, setCopied] = useState(false);
  const [eventD, setEvent] = useState<IEventDetails | null | undefined>(undefined);

  const fetchEvt = useCallback(async () => {
    try {
      const res = await getEvtById(eventId);
      if (res) {
        setEvent(res);
      } else {
        setEvent(null);
      }
    } catch (error) {
      console.error(error);
    }
  }, [eventId]);

  useIonViewWillEnter(() => {
    if (!eventId) return;
    fetchEvt();
  }, [eventId, fetchEvt]);

  useEffect(() => {
    if (eventD === null) {
      setTimeout(() => router.push("/events"), 3000);
    } else if (eventD) {
      logEvent(analytics, `page_view_Event_Page_${eventD.eventName}`);
    }
  }, [eventD]);

  const copyToClipboard = useCallback(() => {
    if (!eventD) return;
    navigator.clipboard.writeText(`${window.location.href}\n${eventD.eventName} - ${dayjs(eventD.eventDates[0].start).format("YYYY-MM-DD")}`);
    setCopied(true);
  }, [eventD]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  const headerTxt = (event: IEventDetails) => {
    if (i18n.language === "zh") {
      const name = event.eventNameChi ? event.eventNameChi : event.eventName;
      return `${t("event")} ${"- " + name}`;
    } else {
      return `${t("event")} ${"- " + event.eventName}`;
    }
  };

  return (
    <Layout name={eventD ? headerTxt(eventD) : t("m-evt")} back>
      {eventD === null ? (
        <IonGrid>
          <IonRow>
            <IonCol size="12" className="flex-center">
              {t("evtNotFound")}
            </IonCol>
            <IonCol size="12" className="flex-center">
              <IonSpinner />
              <IonText className="ion-margin-horizontal">{t("redirectToHome")}</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : (
        eventD !== undefined && (
          <IonGrid>
            <IonList>
              <IonItem lines="none" style={{ transition: "all 0.8s" }}>
                <IonCol className="ion-text-center">
                  <IonText>
                    <h5>{i18n.language === "en" ? <b>{eventD.eventName}</b> : <b>{eventD.eventNameChi ? eventD.eventNameChi : eventD.eventName}</b>}</h5>
                  </IonText>
                </IonCol>
              </IonItem>

              {eventD.eventPoster && !!eventD.eventPoster.length && <EvtPosterDisplay eventPoster={eventD.eventPoster} />}
              {eventD.organisation && <OrgFieldDisplay name={t("host") + " :"} org={eventD.organisation} />}
              <EvtDateDisplay eventDateType={eventD.eventDateType} eventDates={eventD.eventDates} />
              {!!eventD.website && (
                <IonItem lines="none">
                  <IonText>
                    <b>{t("website")}:</b>
                  </IonText>
                  <IonText
                    className="ion-margin-horizontal"
                    style={{
                      cursor: "pointer",
                      textTransform: "lowercase",
                      textDecoration: "underline",
                    }}
                    color="primary"
                    onClick={() => {
                      if (eventD.website?.startsWith("http")) {
                        window.open(eventD.website);
                      } else {
                        window.open(`http://${eventD.website}`);
                      }
                    }}
                  >
                    {eventD.website}
                  </IonText>
                </IonItem>
              )}
              {!!eventD.description && (
                <IonItem lines="none" className="ion-margin-top">
                  <IonText>
                    <b>{t("eventDes") + " :"}</b>
                    {i18n.language === "en" ? (
                      <p style={{ whiteSpace: "pre-wrap" }}>{`${eventD.description}`}</p>
                    ) : (
                      <p style={{ whiteSpace: "pre-wrap" }}>{`${eventD.descriptionChi ? eventD.descriptionChi : eventD.description}`}</p>
                    )}
                  </IonText>
                </IonItem>
              )}
              {!!eventD.coOrg?.organizationName && <OrgFieldDisplay name={"Co-organiser:"} org={eventD.coOrg} />}
              {!!eventD.supportOrg?.organizationName && <OrgFieldDisplay name={"Support organisation:"} org={eventD.supportOrg} />}
            </IonList>
            <IonRow>
              <IonCol className="ion-text-end">
                <IonButton
                  expand="block"
                  color="medium"
                  onClick={() => {
                    router.push("/events");
                  }}
                >
                  {t("back")}
                </IonButton>
              </IonCol>
              <CopyButton expand color={copied ? "success" : "primary"} copyFunction={() => (!copied ? copyToClipboard() : {})} text={copied ? t("copiedToClipboard") : t("copyEventDetails")} />
            </IonRow>
            <br />
          </IonGrid>
        )
      )}
      {/* <IonLoading isOpen={eventD === undefined} message={`${t("loading")}`} /> */}
    </Layout>
  );
}

export default EventPage;
