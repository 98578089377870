import React from "react";
import { IonCol, IonContent, IonGrid, IonLabel, IonLoading, IonModal, IonRange, IonRow } from "@ionic/react";
import { analytics, getAllEvts } from "app/firebase";
import { setViewOrg } from "app/slices/eventSlices";
import { RootState } from "app/store";
import { defaultOrg } from "app/variables";
import BackFooter from "components/BackFoorter";
import ListEvents from "components/ListEvents";
import ListMapSwitch from "components/ListMapSwitch";
import MapEvents from "components/MapEvents";
import { ModalHeader } from "components/ModalHeader";
import { OrgCardContent } from "components/OrgCardContent";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "./Page";
import { logEvent } from "firebase/analytics";

export const DEFAULT_TILT = 25;
export const DEFAULT_ANGLE = 0;

function Events() {
  const { t } = useTranslation();
  const [view, setView] = useState<"list" | "map">("list");
  const [isLoading, setIsLoading] = useState(false);
  const [tilt, setTilt] = useState(DEFAULT_TILT);
  const [angle, setAngle] = useState(DEFAULT_ANGLE);
  const [isVector, setIsVector] = useState(false);
  const rdxEvt = useSelector((state: RootState) => state.events);
  const focusMapEvt = rdxEvt.focusMapEvent;

  const handleFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      await getAllEvts();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetch();
    logEvent(analytics, "page_view_Events");
  }, [handleFetch]);

  useEffect(() => {
    if (focusMapEvt && view === "list") {
      setView("map");
    }
  }, [focusMapEvt]);

  return (
    <Layout
      name={t("m-evt")}
      extraButton={<ListMapSwitch view={view} setView={setView} />}
      footer={
        view === "map" &&
        isVector && (
          <IonRow>
            <IonCol className="ion-text-center">
              <IonRange
                className="ion-padding-horizontal ion-no-padding"
                aria-label="Angle range"
                pin
                min={-180}
                max={180}
                defaultValue={0}
                ticks={true}
                snaps={true}
                pinFormatter={(value: number) => `${value}º`}
                onIonChange={(e) => setAngle(e.detail.value as number)}
              />
              <IonLabel>{`${t("mapAngle")} ${angle}º`}</IonLabel>
            </IonCol>
            <IonCol className="ion-text-center">
              <IonRange
                className="ion-padding-horizontal ion-no-padding"
                aria-label="Tilt range"
                pin
                min={0}
                max={68}
                defaultValue={0}
                ticks={true}
                snaps={true}
                pinFormatter={(value: number) => `${value}º`}
                onIonChange={(e) => setTilt(e.detail.value as number)}
              ></IonRange>
              <IonLabel>{`${t("mapTilt")} ${tilt}º`}</IonLabel>
            </IonCol>
          </IonRow>
        )
      }
    >
      <IonContent fullscreen style={{ position: "absolute", left: view === "list" ? 0 : "-100%", transition: "all 0.6s" }}>
        <ListEvents />
        {/* // UI: bottom padding missing for some reaason */}
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol>
              <br />
              <br />
              <br />
              <br />
              <br />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonContent fullscreen style={{ position: "absolute", left: view === "map" ? 0 : "100%", transition: "all 0.6s" }}>
        <MapEvents
          angle={angle}
          tilt={tilt}
          setIsVector={setIsVector}
          isBlur={view === "list"}
          onReset={() => {
            setTilt(DEFAULT_TILT);
            setAngle(DEFAULT_ANGLE);
          }}
        />
      </IonContent>
      <IonLoading isOpen={isLoading} message={`${t("loading")}`} />
    </Layout>
  );
}

export default Events;

export const OrgModal = () => {
  const rdxEvt = useSelector((state: RootState) => state.events);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <IonModal isOpen={!!rdxEvt.viewOrg} onDidDismiss={() => dispatch(setViewOrg(null))}>
      <ModalHeader title={t("organisationDetails")} closeButton closeFunction={() => dispatch(setViewOrg(null))} />
      <IonContent>
        <OrgCardContent org={rdxEvt.viewOrg ?? defaultOrg} showName displayType="card" />
      </IonContent>
      <BackFooter onClick={() => dispatch(setViewOrg(null))} />
    </IonModal>
  );
};
