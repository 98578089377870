import React from "react";
import { IonButton, IonCol, IonGrid, IonItem, IonList, IonRow, IonSpinner, IonText, useIonRouter, useIonViewWillEnter } from "@ionic/react";
import { IIntEvent } from "app/variables";
import { OrgFieldDisplay } from "components/EventCard";
import dayjs from "dayjs";
import i18n from "i18n";
import { Layout } from "pages/Page";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { analytics, getIntEvtById } from "app/firebase";
import EvtPosterDisplay from "components/EvtPosterDisplay";
import EvtDateDisplay from "components/EvtDateDisplay";
import CopyButton from "components/CopyButton";
import { logEvent } from "firebase/analytics";

function IntEventPage() {
  const { eventId } = useParams<{ eventId: string }>();
  const { t } = useTranslation();
  const router = useIonRouter();
  const [copied, setCopied] = useState(false);
  const [event, setEvent] = useState<IIntEvent | null | undefined>(undefined);

  const fetchEvt = useCallback(async () => {
    try {
      const res = await getIntEvtById(eventId);
      if (res) {
        setEvent(res);
      } else {
        setEvent(null);
      }
    } catch (error) {
      console.error(error);
    }
  }, [eventId]);

  useIonViewWillEnter(() => {
    if (!eventId) return;
    fetchEvt();
  }, [eventId, fetchEvt]);

  useEffect(() => {
    if (event === null) {
      setTimeout(() => router.push("/net-events"), 3000);
    } else if (event) {
      logEvent(analytics, `page_view_${event.eventName}`);
    }
  }, [event]);

  const copyToClipboard = () => {
    if (!event) return;
    navigator.clipboard.writeText(`${window.location.href}\n${event.eventName} - ${dayjs(event.eventDates[0].start).format("YYYY-MM-DD HH:mm")}\n${event.meetingLink}`);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  const headerTxt = (event: IIntEvent) => {
    if (i18n.language === "zh") {
      const name = event.eventNameChi ? event.eventNameChi : event.eventName;
      return `${t("m-netEvt")} ${"- " + name}`;
    } else {
      return `${t("m-netEvt")} ${"- " + event.eventName}`;
    }
  };

  return (
    <Layout name={event ? headerTxt(event) : t("m-netEvt")} back>
      {event === null ? (
        <IonGrid>
          <IonRow>
            <IonCol size="12">{t("evtNotFound")}</IonCol>
            <IonCol size="12">
              <IonSpinner />
              {t("redirectToHome")}
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : (
        event !== undefined && (
          <IonGrid>
            <IonList>
              <IonItem lines="none" style={{ transition: "all 0.8s" }}>
                <IonCol className="ion-text-center">
                  <IonText>
                    <h5>{i18n.language === "en" ? <b>{event.eventName}</b> : <b>{event.eventNameChi ? event.eventNameChi : event.eventName}</b>}</h5>
                  </IonText>
                </IonCol>
              </IonItem>
              {event.eventPoster && !!event.eventPoster.length && <EvtPosterDisplay eventPoster={event.eventPoster} />}
              {event.organisation && <OrgFieldDisplay name={t("host") + " :"} org={event.organisation} />}
              <EvtDateDisplay eventDateType={event.eventDateType} eventDates={event.eventDates} />
              <IonRow>
                <IonCol size="12" className="ion-padding-horizontal">
                  <IonText className="font">
                    <b>{t("event/meetingLink")}:</b>
                  </IonText>
                </IonCol>
                <IonCol className="ion-padding-horizontal">
                  <IonText
                    style={{
                      cursor: "pointer",
                      textTransform: "lowercase",
                      textDecoration: "underline",
                    }}
                    color="primary"
                    onClick={() => {
                      if (event.website?.startsWith("http")) {
                        window.open(event.website);
                      } else {
                        window.open(`http://${event.website}`);
                      }
                    }}
                  >
                    {event.meetingLink}
                  </IonText>
                </IonCol>
              </IonRow>
              {!!event.website && (
                <IonItem lines="none">
                  <IonText>
                    <b>{t("website")}:</b>
                  </IonText>
                  <IonText
                    className="ion-margin-horizontal"
                    style={{
                      cursor: "pointer",
                      textTransform: "lowercase",
                      textDecoration: "underline",
                    }}
                    color="primary"
                    onClick={() => {
                      if (event.website?.startsWith("http")) {
                        window.open(event.website);
                      } else {
                        window.open(`http://${event.website}`);
                      }
                    }}
                  >
                    {event.website}
                  </IonText>
                </IonItem>
              )}
              {!!event.description && (
                <IonItem lines="none" className="ion-margin-top">
                  <IonText>
                    <b>{t("eventDes") + " :"}</b>
                    {i18n.language === "en" ? (
                      <p style={{ whiteSpace: "pre-wrap" }}>{`${event.description}`}</p>
                    ) : (
                      <p style={{ whiteSpace: "pre-wrap" }}>{`${event.descriptionChi ? event.descriptionChi : event.description}`}</p>
                    )}
                  </IonText>
                </IonItem>
              )}
              {!!event.coOrg?.organizationName && <OrgFieldDisplay name={"Co-organiser:"} org={event.coOrg} />}
              {!!event.supportOrg?.organizationName && <OrgFieldDisplay name={"Support organisation:"} org={event.supportOrg} />}
            </IonList>
            <br />
            <IonRow>
              <IonCol>
                <IonButton
                  expand="block"
                  color="medium"
                  onClick={() => {
                    router.push("/net-events");
                  }}
                >
                  {t("back")}
                </IonButton>
              </IonCol>
              <CopyButton expand color={copied ? "success" : "primary"} copyFunction={() => (!copied ? copyToClipboard() : {})} text={copied ? t("copiedToClipboard") : t("copyEventDetails")} />
            </IonRow>
          </IonGrid>
        )
      )}
      {/* <IonLoading isOpen={event === undefined} message={`${t("loading")}`} /> */}
    </Layout>
  );
}

export default IntEventPage;
